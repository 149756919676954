import firebase from 'firebase';

/* FOR PRODUCTION USE THIS */
// var firebaseConfig = {
//   apiKey: "AIzaSyDqEdbsVkh04wofMIs2xtXCyQFptfy4eaE",
//   authDomain: "csi-app-farm.firebaseapp.com",
//   databaseURL: "https://csi-app-farm.firebaseio.com",
//   projectId: "csi-app-farm",
//   storageBucket: "csi-app-farm.appspot.com",
//   messagingSenderId: "993380490931",
//   appId: "1:993380490931:web:404bc023af2ff7ca"
// };

/* FOR DEVELOPMENT, PRESTAGING, AND STAGING, USE THIS */
// var firebaseConfig = {
//   apiKey: "AIzaSyD367Kv3GFNaebR0kHyufeiTsPy4IBryDA",
//   authDomain: "junibook-c7315.firebaseapp.com",
//   databaseURL: "https://junibook-c7315.firebaseio.com",
//   projectId: "junibook-c7315",
//   storageBucket: "junibook-c7315.appspot.com",
//   messagingSenderId: "495257805191",
//   appId: "1:495257805191:web:fbfe930352d8ab193eb322"
// };

const firebaseConfig = {
  apiKey: "AIzaSyBBKRFcbbYDWJMAxJKAGnDa5cUCxcKZnxg",
  authDomain: "csapp-suite.firebaseapp.com",
  databaseURL: "https://csapp-suite.firebaseio.com",
  projectId: "csapp-suite",
  storageBucket: "csapp-suite.appspot.com",
  messagingSenderId: "738571693866",
  appId: "1:738571693866:web:19a99f76e04faa66313853"
};

firebase.initializeApp(firebaseConfig);
