import React, { useState, Fragment, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import { usePageTransitions} from '../../../vinciToolbox';
import { CCButton } from '../../components';
import './Plan.css'

export const Plan = (props)=> {
  let headerHeight = props.headerHeight;
  let pt = usePageTransitions('/plan', 1);
  let transition = useSpring({
    transform: `translateY(${pt.animate ? headerHeight : props.screenSize.height}px)`, 
    display: pt.render ? 'block' : 'none'
  });
  let path = window.location.pathname;  
  let refSubpageHeader = useRef(null);
  let [tabSlide, setTabSlide] = useState(false);
  let tabBar = useSpring({
    height: '100%', width: '50%',
    backgroundColor: '#F8C115',
    transform: `translateX(${tabSlide ? props.wrapper.style.width / 2 : 0}px)`,
  })
  let contentWrapperHeight = refSubpageHeader.current ? refSubpageHeader.current.offsetHeight : 0;
  let subpageWrapper = useSpring(
    {
      position: 'relative',
      display: pt.render ? 'flex' : 'none',
      width: props.wrapper.width * 2, 
      height:  props.wrapper.style.height - contentWrapperHeight,
      transform: `translateX(${tabSlide ? -props.wrapper.style.width : 0}px)`, 
    });
  return(
    <Fragment>
      <animated.div className="Phases-sidein-page" style={transition}>
        <div>
          <div style={{position: 'absolute', width: props.wrapper.width, height: props.screenSize.height - headerHeight}}>
            <div style={{overflowX: 'hidden', overflowY: 'auto',}}>
              <div  ref={refSubpageHeader} >
                <div style={{display: 'flex', fontWeight: 600, cursor: 'pointer'}}>
                  <div style={{
                      width: '50%', textAlign: 'center', padding: '14px 0',
                      color: tabSlide ? '#888888' : '#000000'
                    }} 
                    onClick={(e)=> setTabSlide(false)}>Developmental Plan</div>
                    <div style=
                    {{
                      width: '50%', textAlign: 'center', padding: '14px 0',
                      color: tabSlide ? '#000000' : '#888888'
                    }} 
                    onClick={(e)=> setTabSlide(true)}>Dev. Relationships</div>
                  </div>
                  <div style={{width: '100%', height: 7, marginTop: -7}}>
                    <animated.div style={tabBar}></animated.div>
                  </div>
                </div>
              <animated.div style={subpageWrapper}>
                <div style={{overflowX: 'hidden', overflowY: 'auto', width: '100%', opacity: tabSlide ? 0 : 1}}>
                  <div className="Phases-description-block" style={{backgroundColor: '#f4f4f4'}}>
                    Create your plan by completing each option below.
                  </div>
                  <div style= {{height: props.wrapper.style.height-200}}>
                    <div style= {{
                        display: 'flex', flexDirection: 'column', 
                        alignContent: 'flex-start', justifyContent: 'space-around',
                        margin: '20px 30px',
                        fontSize: '100%',
                        fontWeight: 600,
                        color: '#777777',
                        // border: '1px solid red',
                        height: '90%',
                        maxHeight: 500
                      }}
                    >
                    <Fragment>                      
                    <div className="Assess-button-wrapper" style={{marginTop: 10}}>
                        <div onClick={()=> props.rt.nav('/plan/resources/add_resources')}>
                          <CCButton>The Resource Library</CCButton>
                        </div>
                      </div>
                      <div className="Assess-button-wrapper" style={{marginTop: 10}}>
                        <div onClick={()=> props.rt.nav('/plan/resources')}>
                          <CCButton>Saved Resources</CCButton>
                        </div>
                      </div>
                      <div className="Assess-button-wrapper" style={{marginTop: 10}}>
                          <div onClick={()=> props.rt.nav('/plan/live_learning')}>
                            <CCButton>Live Leadership Training</CCButton>
                          </div>
                        </div>
                        <div className="Assess-button-wrapper" style={{marginTop: 10}}>
                          <div onClick={()=> props.rt.nav('/plan/challenging_assignment')} >
                            <CCButton>Challenging Assignment</CCButton>
                          </div>
                        </div>
                      </Fragment>
                    </div>
                  </div>
                </div>
                <div style={{width: '100%', opacity: tabSlide ? 1 : 0}}>
                  <div className="Phases-description-block" style={{backgroundColor: '#f4f4f4'}}>
                    Define your developmental relationships using the links below.
                  </div>
                  <div style= {{height: props.wrapper.style.height-200}}>
                    <div style= {{
                      display: 'flex', flexDirection: 'column', 
                      alignContent: 'flex-start', justifyContent: 'space-around',
                      margin: '20px 30px',
                      fontSize: '100%',
                      fontWeight: 600,
                      color: '#777777',
                      // border: '1px solid red',
                      height: '90%',
                      maxHeight: 500
                    }}
                  >
                      <Fragment>
                        <div className="Assess-button-wrapper" style={{marginTop: 5}}>
                          <div onClick={()=> props.rt.nav('/plan/dev_relationships:manager')}>
                            <CCButton>Manager</CCButton>
                          </div>
                        </div>
      
                        <div className="Assess-button-wrapper" style={{marginTop: 5}}> 
                          <div onClick={()=> props.rt.nav('/plan/dev_relationships:mentor')}>
                            <CCButton>Mentor / Sounding Board</CCButton>
                          </div>
                        </div>
      
                        <div className="Assess-button-wrapper" style={{marginTop: 5}}> 
                          <div onClick={()=> props.rt.nav('/plan/dev_relationships:model')}>
                            <CCButton>Role Model / Dialogue Partner</CCButton>
                          </div>
                        </div>
      
                        <div className="Assess-button-wrapper" style={{marginTop: 5}}> 
                          <div onClick={()=> props.rt.nav('/plan/dev_relationships:partner')}>
                            <CCButton>Challenge Partner</CCButton>
                          </div>
                        </div>
      
                        <div className="Assess-button-wrapper" style={{marginTop: 0}}> 
                          <div onClick={()=> props.rt.nav('/plan/dev_relationships:cohort')}>
                            <CCButton>Cohort</CCButton>
                          </div>
                        </div>
                      </Fragment>
                    </div>
                  </div>
                </div>
              </animated.div>
            </div>
          </div>
        </div>
      </animated.div>
    </Fragment>
  )
}


export const CCButtonOnDark = (props) => {
  const refButton = useRef(null);
  let height = 50;
  return(
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} name={props.name}>
      <button className="CCButtonOnDark" ref={refButton} ></button>
      <div style={{marginTop: -37, zIndex: 0, cursor: 'pointer'}}>{props.children}</div>
      <div style={{marginTop: 35, color: '#999999'}}>{props.label}</div>
    </div>
  );
}
