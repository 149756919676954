import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useSpring, animated } from 'react-spring';
// import { BackgroundOfDisks } from './svg/svg-new-compass';
import './NewHomeCompass.css'
import { CustomEx } from './svg/svg_icons';

const NewHomeCompass = (props) => {
  // console.log('props=', props)
  let [showCompass, setShowCompass] = useState(false);

  let refCompassDisk = useRef(null);


  
  useEffect(() => {
    setShowCompass(false);
  },[])

  const handleOnLoad = () => {
    setShowCompass(true);
  }

  const getAngle = (cx, cy, ex, ey) => {
    var p1 = {
      x: cx,
      y: cy
    };
    var p2 = {
      x: ex,
      y: ey
    };
    // // angle in radians
    var angleRadians = Math.atan2(p2.y - p1.y, p2.x - p1.x);
    
    // angle in degrees
    var angleDeg = Math.atan2(p2.y - p1.y, p2.x - p1.x) * 180 / Math.PI;
    console.log(`Inside getAngle(): Radians: ${angleRadians} Degrees: ${angleDeg}`)
    return angleDeg;
  }

  let [angle, setAngle] = useState(0);
  // if(angle){
    // console.log('angle=', angle)
  // }
  
  // let [clickPoint, setClickPoint] = useState({x: 0, y: 0});
  let clickPoint = {x: 0, y: 9}
  
  // console.log('clickpoint 1=', clickPoint);
  let compassCenter = {
    x: 0, 
    y: 0,
  }
  
  let compassRect = {}
  
  useEffect(() => {
    compassRect = refCompassDisk.current.getBoundingClientRect();  
    // console.log('refCompassDisk.current=', refCompassDisk.current.offsetWidth);
  })



  let [blur, setBlur] = useState(false);
  let [competencyText, setCompetencyText] = useState('')


  // if( refCompassDisk.current){
    // console.log('refCompassDisk.current=', refCompassDisk.current && refCompassDisk.current.offsetWidth )
  // }
  const handleCompassClick = (angle, competency) => {
    setBlur(true);
    setAngle(angle);
    setCompetencyText(competency)
  }


  // console.log('aspect ratio of wrapper=', props.wrapper.style.width / props.wrapper.style.height)
  // Reduce size of compass when vertical height is limited.
  let size = props.wrapper && props.wrapper.width - 45;
  if(props.wrapper.style.width / props.wrapper.style.height > 0.630){
    size = props.wrapper && props.wrapper.width - 115;
  }
  if(props.wrapper.style.width / props.wrapper.style.height > 0.655){
    size = props.wrapper && props.wrapper.width - 145;
  }
  if(props.wrapper.style.width / props.wrapper.style.height > 0.70){
    size = props.wrapper && props.wrapper.width - 165;
  }
  if(props.wrapper.style.width / props.wrapper.style.height > 0.76){
    size = props.wrapper && props.wrapper.width - 235;
  }    
  
  let clickArea = { 
    top: props.wrapper.width
     * -0.12,
    left:  190 * (size / 555) ,
    height: props.wrapper.width * .23, 
    width: props.wrapper.width * .13,
    cursor: 'pointer',
    color: 'white',
  }

  let angleStyle = useSpring(
    { 
      config: {mass: 2, tension: 55, friction: 15},
      overflow: 'visible', position: 'absolute', 
      top: size / 2,
      left: size / 2,
      width: 1, height: 1, 
      transform: `rotate(${angle}deg)`,
      // border: '2px solid blue', background: 'rgba(0,0,0,0.8)'
    }
  )

  return(
    <Fragment>
    <div ref={refCompassDisk} className="noSelect" 
      style={{display: showCompass ? 'block' : 'none', position: 'relative', left: 0, width: size, height: size, // borderRadius: '50%',
        // border: '1px solid blue'
      }}>
      <div style={{position: 'absolute',width: size, height: size }}>
        <BackgroundOfDisks />
      </div>

      <div style={{position: 'absolute', width: size, height: size}} >
        <OuterDiskContent angle={angle} />
      </div>
      <div style={{position: 'absolute', width: size, height: size}}>
        <InnerDiskContent angle={angle} />
      </div>
      <div style={{position: 'absolute', width: size, height: size}}>
        <Hairline />
      </div>
      <div style={{position: 'absolute', width: size, height: size}}>
        <UserPhoto photoUrl={props.photoUrl} handleOnLoad={handleOnLoad} />
      </div>
      <div style={{position: 'absolute', width: size, height: size}}>
        <PopupOverPhoto blur={blur} setBlur={setBlur} setAngle={setAngle} 
          setCompetencyText={setCompetencyText} competencyText={competencyText}  
          wrapper={props.wrapper} size={size}
        />
      </div>

      <animated.div style={angleStyle} >
        <div className="main" style={{ transform: 'rotate(-70deg)' }} >
          <div className="circle" style={clickArea} onClick={()=> handleCompassClick(-25, 'Establishing Foundation') }></div>
        </div>
        <div className="main" style={{transform: 'rotate(-30deg)'}}>
          <div className="circle" style={clickArea}  onClick={()=> handleCompassClick(-60, 'Creative & Analytical Thinking')} ></div>
        </div>
        <div className="main" style={{transform: 'rotate(10deg)'}}>
          <div className="circle" style={clickArea}  onClick={()=> handleCompassClick(-98, 'Results Orientation')} ></div>
        </div>

        <div className="main" style={{ transform: 'rotate(50deg)' }} >
          <div className="circle" style={clickArea} onClick={()=> handleCompassClick(-135, 'Inspiring & Motivating Others') }></div>
        </div>
        <div className="main" style={{transform: 'rotate(90deg)'}}>
          <div className="circle" style={clickArea}  onClick={()=> handleCompassClick(-174, 'Leading People for Performance')} ></div>
        </div>
        <div className="main" style={{transform: 'rotate(130deg)'}}>
          <div className="circle" style={clickArea}  onClick={()=> handleCompassClick(-216, 'Building Winning Teams')} ></div>
        </div>

        <div className="main" style={{ transform: 'rotate(170deg)' }} >
          <div className="circle" style={clickArea} onClick={()=> handleCompassClick(-263, 'Business & Financial Acumen') }></div>
        </div>
        <div className="main" style={{transform: 'rotate(210deg)'}}>
          <div className="circle" style={clickArea}  onClick={()=> handleCompassClick(-303, 'Strategic Planning')} ></div>
        </div>
        <div className="main" style={{transform: 'rotate(250deg)'}}>
          <div className="circle" style={clickArea}  onClick={()=> handleCompassClick(-343, 'Leading Change')} ></div>
        </div>   
      </animated.div>
    </div>
    </Fragment>
  )
}

export default NewHomeCompass;


const UserPhoto = (props) => {
  return(
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%'}}>
      <img src={props.photoUrl} 
        style={{width: '46%', height: '46%', borderRadius: '50%', border: '1px solid rgba(255,255,255,0.125)', overflow: 'hidden', boxShadow: '0 5px 5px rgba(0,0,0,0.8)'}} 
        onLoad={()=> props.handleOnLoad() }
      />
    </div>
  )
}



const PopupOverPhoto = (props) => {
  let transition = useSpring({config: {tension: 120},
    display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center',
    width: '46%', height: '46%', borderRadius: '50%', 
    color: '#e6e6e6', 
    backdropFilter: props.blur ? `blur(${5}px)` : `blur(${0}px)`, 
    WebkitBackdropFilter: props.blur ? `blur(${5}px)` : `blur(${0}px)`, 
    background: props.blur ? 'rgba(40,40,40,0.6)' : 'rgba(0,0,0,0)',
  });

  // console.log('test=', props.size / 555)

  let outerDiv = {
    display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%',
  }

  return(
    <div style={outerDiv}>
      <animated.div style={transition} onClick={()=> props.setBlur(false)}
        onClick={()=> {props.setAngle(0); props.setCompetencyText(''); props.setBlur(false) }  }
      >
        <div style={{padding: 10, marginTop: 65 * (props.size / 555 ), textAlign: 'center',
          fontSize: 22 * (props.size / 555 ),
        }}>
          {props.competencyText}
        </div>
        <div style={{cursor: 'pointer', transform: 'scale(1.3) translateY(25px)', opacity: props.competencyText ? 0.4 : 0}}>
          <CustomEx name="compass-text" color="#e6e6e6" size={80} backgroundColor="transparent" />
        </div>
      </animated.div>
    </div>
  )
}


const BackgroundOfDisks = (props) => {
  return(
    <img src='https://firebasestorage.googleapis.com/v0/b/csapp-suite.appspot.com/o/Layout%20assets%2FHomepage-compass-design-lighter-center%60.png?alt=media&token=ac310730-ce06-4760-bac1-171a7df75e1e' 
      style={{width: '100%', height: 'auto'}} 
    />
  )
}

const OuterDiskContent = (props) => {

  let transition = useSpring({
    config: {mass: 2, tension: 55, friction: 15},
    transform: `rotate(${props.angle}deg)` 
  })
  
  return(
    <animated.div style={transition} onClick={(e)=> console.log('You clicked me')}>
      <img src='https://firebasestorage.googleapis.com/v0/b/csapp-suite.appspot.com/o/Layout%20assets%2FNewCompassOuterContent.png?alt=media&token=d699e1b3-130d-4e44-9d18-e3ae87664539' 
        style={{width: '100%', height: 'auto'}} 
      />
    </animated.div>
  )
}


const InnerDiskContent = (props) => {
  let transition = useSpring({
    config: {mass: 2, tension: 60, friction: 15},
    transform: `rotate(${props.angle}deg)` 
  })

  return(
    <animated.div style={transition}>
      <img src='https://firebasestorage.googleapis.com/v0/b/csapp-suite.appspot.com/o/Layout%20assets%2FNewCompassInnerContent.png?alt=media&token=f8d99644-301c-41a0-81c5-6adfbadafdeb' 
        style={{width: '100%', height: 'auto'}} 
      />
    </animated.div>
  )
}


const Hairline = (props) => {
  return(
    <animated.div >
      <img src='https://firebasestorage.googleapis.com/v0/b/csapp-suite.appspot.com/o/Layout%20assets%2FNewCompassHairline.png?alt=media&token=5ce71cb0-9dce-48e8-a6a6-745b41a81124' 
        style={{width: '100%', height: 'auto', pointerEvents: 'none'}} 
      />
    </animated.div>
  )
}