import React, { useState, useEffect, Fragment} from 'react';
import {  usePathSegments} from '../../../../../../../vinciToolbox';
import { OneResource } from '../OneResource';
import firebase from 'firebase';

export const Resource = (props) => {
  // console.log('props=', props)

  let segment = usePathSegments();
  segment = segment[4] && segment[4].split(':') 

  // let [segment, setSegment] = useState(['', 0, 0]);
  // let segment = ['', 0, 0]
  let resources = [];
  let books = [];
  let videos = [];
  let weblinks = [];
  let podcasts = []

  let theBooks = '';
  let theVideos = '';
  let theWeblinks = '';
  let thePodcasts = '';
  let [resourceList, setResourceList] = useState({});

  if(resourceList){
    // console.log('resourceList=', resourceList);
  }
  let [collection, setCollection] = useState([]);

  let db = firebase.firestore();
  let docRef = null;
  docRef = db.doc(`campbell_compass/${props.user && props.user.email}_collection`); 
  
  useEffect(() => {
    docRef.get()
    .then((doc) => {
      // console.log('doc.data()=', doc.data().collection);
      setCollection(doc.data().collection);

      // console.log('The collection has been re-rendered')

    }).catch((error) => {
      console.log('ERROR in retrieving save resources doc: ', error)
    })
  },[props])

  const checkIfSaved = (theResource) => {
    // console.log('theResource.title=', theResource.title)
    for(let i = 0; i < collection.length; ++i){
      // console.log('collection[i].title', collection[i].title);
      if(theResource.title === collection[i].title){
        return true
      }
    }
  }
  
  let unsubscribe = null;
  useEffect(()=>{
    const getRealtimeUpdates = () =>{
      unsubscribe = db.collection(`campbell_compass`).doc(`${firebase.auth().currentUser.email}_collection`)
      .onSnapshot((doc)=>{
        if(doc && doc.exists) {
          setCollection(doc.data().collection);
        }else{
          console.log('onSnapshot doc not found')
        }
      }, (error)=> {
        console.log('error=', error);
        // return( null );
      });
    }
    getRealtimeUpdates();  
  },[props, segment && segment[0] != '/resources']);

  if(segment && segment[0] != '/resources'){
    if(unsubscribe){
      unsubscribe();
    }
  }

  const getIfSaved = (title) => {
    for(let i = 0; i < collection.length; ++i){
      if(collection[i].title === title){
        return true;
      }
    }
    return false;
  }

  useEffect(()=>{   
    // console.log('props=', props)
    if(props.data && props.data.status === 'success'){
      resources = []
      if(props.data ){
        books = props.data && props.data.data.books;
        // console.log('books=', books)
        if(books){
          for(let i=0; i< books.length; ++i){
            books[i]['index'] = i;
            books[i]['isSaved'] = getIfSaved(books[i].title);
          }
        }else{
          books = []
        }

        videos = props.data && props.data.data.videos;
        // console.log('videos=', videos)
        if(videos){
          for(let i=0; i< videos.length; ++i){
            videos[i]['index'] = i;
            videos[i]['isSaved'] = getIfSaved(videos[i].title);
          }
        }else{
          videos = []
        }

        weblinks = props.data && props.data.data.weblinks;
        // console.log('weblinks=', weblinks)
        if(weblinks){
          for(let i=0; i< weblinks.length; ++i){
            weblinks[i]['index'] = i;
            weblinks[i]['isSaved'] = getIfSaved(weblinks[i].title);
          }
        }else{
          weblinks = []
        }

        podcasts = props.data && props.data.data.podcasts;
        if(podcasts){
          for(let i=0; i< podcasts.length; ++i){
            podcasts[i]['index'] = i;
            podcasts[i]['isSaved'] = getIfSaved(podcasts[i].title);
          }
        }else{
          podcasts = []
        }

        // console.log('books=', books)

        theBooks = books.map((r)=>
          <div key={r.index}>   
            <OneResource type="books" title={r.title} description={r.description} 
              image={r.image} 
              url={r.url} key={r.index} id={r.index} user={props.user} rating={r.rating}
              collection={collection} isSaved={r.isSaved}
            />
            
          </div>
        );

        theVideos = videos.map((r)=>
          <div key={r.index}>
            <OneResource type="videos" title={r.title} description={r.description}  
              image={r.image} 
              url={r.url} key={r.index} id={r.index} user={props.user} rating={r.rating}
              collection={collection} isSaved={r.isSaved}
            />
          </div>
        );

        theWeblinks = weblinks.map((r)=>
          <div key={r.index}>
            <OneResource type="weblinks" title={r.title} description={r.description}  
              image={r.image} 
              url={r.url} key={r.index} id={r.index} user={props.user} rating={r.rating}
              collection={collection} isSaved={r.isSaved}
            />
          </div>
        );
        
        thePodcasts = podcasts.map((r)=>
          <div key={r.index}>
            <OneResource type="podcasts" title={r.title} description={r.description}  
              image={r.image} 
              url={r.url} key={r.index} id={r.index} user={props.user} rating={r.rating}
              collection={collection} isSaved={r.isSaved}
            />
          </div>
        );
      }

      setResourceList({
        books: theBooks,
        videos: theVideos,
        weblinks: theWeblinks,
        podcasts: thePodcasts
      })
    }
  },[props.data, collection])


  // let [show, setShow] = useState(false)
  // useEffect(()=>{
  //   if(segment && segment[0]==='/resources'){
  //     setTimeout(()=>{setShow(true)}, 1000)
  //   }else{
  //     setShow(false)
  //   }

  // },[segment && segment[0]])

  let libraryResources = <Fragment>
    {props.resourceType==='books'  ? resourceList.books : ''}
    {props.resourceType==='videos'  ? resourceList.videos : ''}
    {props.resourceType==='weblinks'  ? resourceList.weblinks : ''}
    {props.resourceType==='podcasts'  ? resourceList.podcasts : ''}
  </Fragment>

  return(
    <div style={{
      width: props && props.width, 
      // height: 500, // props.wrapperHeight,
      // height: props.wrapper.style.height  - props.headerHeight, 
      //height: props.wrapper.style && props.wrapper.style.height, 
      // overflowY: 'scroll', overflowX: 'scroll',
      borderTop: '1px solid rgba(0,0,0,0.15)',
      backgroundColor: '#e6e6e6',
      // border: '1px solid blue'
    }}>
    <Fragment>
    
      <div style={{}}>
        {libraryResources}
      </div>
    </Fragment>
    </div> 
  )
}


