import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import { usePageTransitions, usePathSegments, goHome } from '../../../vinciToolbox';
import { CustomCheck, ChallengingAssignmentIcon } from '../../svg/svg_icons';
import firebase from 'firebase';
import getTimestamp from './getTimestamp';

let db = firebase.firestore();




const ChallengingAssignment = (props) => {
  // console.log('props: ', props)
  let [challengingAssignment, setChallengingAssignment] = useState({})
  let unsubscribe = null;
  useEffect(()=>{
    const getRealtimeUpdates = () =>{
      unsubscribe = db.collection(`campbell_compass`).doc(`${firebase.auth().currentUser.email}_challenging_assigment`)
      .onSnapshot((doc)=>{
        if (doc && doc.exists) {
          let data = doc.data().challengingAssignment;
          if( data ){
            // console.log('XXXXXXXXXXXXXXXXXXXXXXXX  doc.data().challengingAssignment=', data )
            setChallengingAssignment({
              title: data.title,
              summary: data.summary,
              description: data.description,
              // type: data.type,
            });
          } 
        }else{
          console.log('Document was not found.');
          
        }
      }, (error)=> {
        console.log('error=', error);
        // return( null );
      });
    }
    getRealtimeUpdates();  
  },[]);
  if(!props.pt.render, props){
    if(unsubscribe){
      unsubscribe();
    }
  }
 
  const handleContinue = (index) => {
    console.log('inside handleContinue');

    // This logs the Challenging Assignment to the Competion Log 
    let docRef = db.collection(`campbell_compass`).doc(`${firebase.auth().currentUser.email}_challenging_assigment`);
    docRef.get()
    .then((doc) => {
      if(doc && doc.exists){
        let challengingAssignmentObj = doc.data().challengingAssignment;
        challengingAssignmentObj['timestamp'] = getTimestamp()
        console.log('challengingAssignmentObj = ', challengingAssignmentObj );

        // This deletes from Save Resources the item identified by the index number.
        docRef = db.doc(`campbell_compass/${firebase.auth().currentUser.email}_completed`); 
        let completedArray = [];
        docRef.get()
        .then((doc) => {
          if(doc && doc.exists){
            completedArray = doc.data().completed;
            completedArray.push(challengingAssignmentObj);
            docRef = db.doc(`campbell_compass/${firebase.auth().currentUser.email}_completed`);
            docRef.set({
              completed: completedArray
            }, { merge: true })
            .then(()=>{
              console.log('SUCCESS - item added to Completed');
              // This is where you clear the Challenging Assignment fields
              let challengingAssignment = {};
              challengingAssignment['title'] = '';
              challengingAssignment['summary'] = '';
              challengingAssignment['description'] = '';
              let docRef = db.collection(`campbell_compass`).doc(`${firebase.auth().currentUser.email}_challenging_assigment`);
              docRef.set({
                challengingAssignment: challengingAssignment
              })
              .then(() => {
                console.log('SUCCESS - in clearing Challenging Assignment fields')
              }).catch(() => {
                console.log('ERROR - in clearing Challenging Assignment fields')
              });
            }).catch((error)=> {
              console.log('you screwed up!: ', error);
            })

          }else{ // Completed Doc does not exist, so created it
            docRef = db.doc(`campbell_compass/${firebase.auth().currentUser.email}_completed`);
            docRef.set({
              completed: []  // Initialize Completed.
            })
          }
          console.log('doc.data()=', doc.data())
        }).catch(() => {
          console.log('ERROR - when moving Challenging Assigment to Complete');
        });
      }

    }).catch((error) => {
      console.log('ERROR -in moving challenging assignment to the completion log. ', error)
    })
    props.setShowModal(false);
  }


  const handleChecked = (id, data) => {
    // console.log('inside handleChecked - data = ', data)
    props.setModalContent(
      <div>
        <div>
          <div style={{fontSize: '105%', fontWeight: 600, color: '#000',  textAlign: 'center', margin: '10px 0 15px'}}>
            This action will move your Challenging Assignment to Completed 
            and will clear the fields where it was defined.
          </div>
          {/*
          <div style={{fontSize: '100%', fontWeight: 600, color: 'tomato',  textAlign: 'center', margin: '0 0 20px 0'}}>
            This action is non-reversable
          </div>
          */}
        </div>
        <div style={{display: 'flex', justifyContent: 'space-around', marginTop: 15, fontSize: '120%', width: '100%'}}>
          <div style={{ color: '#808080', cursor: 'pointer'}} onClick={()=> props.setShowModal(false) }>
            Cancel
          </div>
          <div style={{
            color: '#F5B01B',
            cursor: 'pointer',
          }} 
            onClick={() => handleContinue(id)}>
            Continue  
          </div>
        </div>
      </div>
    )
    props.setShowModal(true);
  }

  let descriptionRef = useRef(null);
  descriptionRef.current && (descriptionRef.current.dangerouslySetInnerHTML = challengingAssignment.description );

  function createMarkup() {
    return {__html: challengingAssignment.description };
  }
  
  function Description() {
    return <div dangerouslySetInnerHTML={createMarkup()} />;
  }


  let caTitle = challengingAssignment.title;
  let caSummary = challengingAssignment.summary;
  let caDescription = challengingAssignment.description
  return(
    <div >

      { caTitle && caSummary && caDescription ?
        <div style={{marginTop: 0}}>
          <div style={{fontSize: '125%', fontWeight: 800, color: '#444', margin: '35px 25px 0px 25px', paddingBottom: 10, borderBottom: '1px solid #333' }}>
            Challenging Assignment
          </div>

          <div style={{position: 'relative'}}>
            <div style={{position: 'absolute', top: 15, left: 30}}>
              <ChallengingAssignmentIcon size={70} color="#F5B01B" />
            </div>
            <div style={{padding: '15px 25px 30px 80px', cursor: 'pointer'}} >
              <div style={{display: 'flex'}}>
                <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', margin: '0 0px 0 30px', fontWeight: 500, color: '#222'}} >
                  <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', fontWeight: 600, fontSize: '110%', color: '#000'}}>
                    <div style={{width: '100%'}}>
                      {challengingAssignment.title}
                    </div>
                    <div style={{transform: 'scale(1.1)', cursor: 'pointer'}} onClick={()=> handleChecked( challengingAssignment.id )}>
                      <CustomCheck size={25} backgroundColor={false ? "#F9C908" : "#cccccc"} color="#555" borderColor={false ? "transparent" : "#c6c6c6"} />
                    </div>
                  </div>
                </div>
              </div>

              <div style={{fontSize: '95%', margin: '10px 0 0 30px'}}>
                <span style={{fontWeight: 700, color: '#000'}}>Summary:</span> <span style={{fontWeight: 500, color: '#000'}}>
                  {challengingAssignment.summary}
                </span>
              </div>
              <div style={{fontSize: '100%', fontWeight: 600, color: '#000', margin: '10px 0 0 30px'}}>
                <span style={{fontWeight: 700, color: '#000'}}>Full description:</span> <span style={{fontWeight: 500, color: '#000'}}>
                  <Description />
                </span>
              </div>
            </div>
          </div>
        </div> 
        : '' 
      }

    </div>
  )
}

export default ChallengingAssignment;