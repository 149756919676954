// import React, { useState, useEffect, Fragment } from 'react';
import firebase from 'firebase';

export const getPhotoUrlFromEmail = function(email)
{
 return new Promise(function(resolve, reject)
 {
  /*stuff using username, password*/
  var storageRef = firebase.storage().ref();
  storageRef.child('user_photos/' + email + '.jpg').getDownloadURL()
  .then((photo)=>{
    resolve(photo)
  })
  .catch((error)=>{
    // Photo not found so get the downloadURL for 'default.jpg'
    storageRef.child('user_photos/default.png').getDownloadURL()
    .then((dfault)=>{
      resolve(dfault)
    })
    .catch((error)=>{
      reject( 'Error - could not retrieve user images: ', error.message )//reject(error)
    })

  })
 });
}
