import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import { PlusGray, CustomCheck, CustomPlus, CustomMinus } from '../../../../../svg/svg_icons';
import {  usePathSegments} from '../../../../../../vinciToolbox';
import firebase from 'firebase';
import extractDomain from 'extract-domain';
import { Stars } from '../Stars'
import '../../../../../../firebase-init';

export const OneResource = (props) => {
  let [isSaved, setIsSaved] = useState(false);
  // console.log('props.collection=', props.collection)
  let segment = usePathSegments();
  segment = segment[4] && segment[4].split(':');

  let db = firebase.firestore();
  let docRef = null;
  docRef = db.doc(`campbell_compass/${props.user && props.user.email}_collection`); 
  
  useEffect(() => {

      let tempArray = props.collection;  // doc.data().collection;
      for(let i = 0; i < tempArray.length; ++i){
        if(tempArray[i].title === props.title){
          setIsSaved(true);
          // console.log(`${props.title}`)
        }
      }
  },[props])

  let collectionContents = null;
  const handleRemove = (e) => {
    console.log('Inside handleRemove');
    console.log('props.collection=', props.collection)
    // Write to Resource database
    let contents = props.collection;
    console.log('contents=', contents);
    let tempArray = [];
    for(let i = 0; i < contents.length; ++i){
      if(contents[i].title != props.title){
        tempArray.push(contents[i]);
      }
    }
    console.log(`tempArray=`, tempArray );
    docRef.set({
      collection: tempArray
    }).then(() => {
      console.log('SUCCESS in updating Saved Resources')
      // setCollectionContents(tempArray);
    })
  }

  const handleAdd = (e) => {
    // Write to Resource database
    let db = firebase.firestore();
    let docRef = {};
    let accum = [];
    docRef = db.doc(`campbell_compass/${props.user && props.user.email}_collection`); 
    docRef.get()
    .then((doc) => {
      // console.log('doc.data().collection=', doc.data().collection)
      collectionContents = doc.data().collection;
      accum = collectionContents;
      console.log('1 accum=', accum);

      accum.push({
        type: props.type,
        title: props.title,
        description: props.description,
        image: props.image,
        url: props.url,
        rating: props.rating ? props.rating : 0
      })

      console.log('2 accum=', accum);

      docRef.set({
        collection: accum
      }, { merge: true })
      .then(()=>{
        console.log('SUCCESS');
      }).catch((error)=> {
        console.log('you screwed up!: ', error);
      })

    }).catch((error) => {
      console.log('The doc doesn\'t exsit so create it.');

      accum.push({
        type: props.type,
        title: props.title,
        description: props.description,
        image: props.image,
        url: props.url,
        rating: props.rating ? props.rating : 0
      })

      docRef.set({
        collection: accum
      }, { merge: true })
      .then(()=>{
        console.log('SUCCESS');
      }).catch((error)=> {
        console.log('you screwed up!: ', error);
      })
    });

  }




  const imageRef = useRef(null);
  const handleSize = (image) => {
    if(image.naturalHeight > image.naturalWidth){
      image.style.width = '90px'
    }
    image.style.minWidth = '90px'
  }

  // Extract domain from url to show the source of the article
  let [domain, setDomain] = useState('');
  useEffect(()=> {
    setDomain(extractDomain(props.url))
  },[])

  return(
    <div style={{display: 'flex', alignItems: 'center', paddingRight: 20,
      borderBottom: '1px solid rgba(0,0,0,0.30)',
      borderTop: '1px solid #e6e6e6',
      position: 'relative',
      boxSizing: 'border-box',
      //marginRight: 3,
      // border: '1px solid blue'
    }}>
      <div style={{margin: '22px 0 15px 30px', display: 'flex', overflow: 'hidden'}} >
        <div style={{height: 'auto', position: 'relative'}}>
          
          <img src={props.image} style={{ 
            minHeight: 75, width: 'auto', minWidth: 130, maxWidth: 130,
            boxShadow: '0px 2px 5px rgba(0,0,0,0.3)',
            backgroundColor: '#c6c6c6',
            border: 'red' // '1px solid rgba(0,0,0,0.2)'
          }}
          ref={imageRef}
          onLoad={()=> handleSize(imageRef.current)}
          />
        </div>
        <div style={{display: 'flex', width: '100%', flexDirection: 'row', margin: '-4px 0 0 0', overflowX: 'hidden', }} >
          <div style={{margin: '0 0px 0 5px', alignContent: 'stretch', overflow: 'auto'}} onClick={(e)=> { window.open(props.url, "_blank")} }>
            <div style={{margin: '0 20px 4px 15px', fontSize: '90%', fontWeight: 400, color: '#55555'}}>
              <div style={{display: 'flex', justifyContent: 'space-between' }}>
                <div style={{overflow: 'hidden', overflowWrap: 'no-wrap', textOverflow: 'ellipsis', }} >
                  {domain}
                </div>
                <div style={{display: 'flex', justifyContent: 'flex-end', width: 100,}}>
                  {/*<Stars rating={props.rating} />*/}
                </div>
              </div>
            </div>
            <div style={{margin: '0 35px 0 15px', fontWeight: 500, color: '#222'}}>
              {props.title}
            </div>
            <div style={{
              margin: '5px 35px 8px 15px', 
              fontSize: '85%', fonrWeight: 400, color: '#222222', 
              overflow: 'hidden',
              overflowWrap: 'break-word',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 4,
              WebkitBoxOrient: 'vertical',
            }}>{props.description}</div>
          </div>
        </div>
      </div>

      <div style={{ width: 40, height: 45, margin: props.type==='books' ? '10px -3px 0 0' : '10px -3px 0 0', 
        position: 'absolute', top: 2, left: 17, 
      }} >
        <div  onClick={(e)=> props.isSaved ? handleRemove(e) : handleAdd(e) /*setMenu(true)*/ } style={{display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}}>
          {props.isSaved ? 
            <CustomMinus size={25} backgroundColor={props.isSaved ? "#F9C908" : "#cccccc"} color="#555" borderColor="transparent" style={{}} />
          :
            <CustomPlus size={25} backgroundColor={props.isSaved ? "#F9C908" : "#cccccc"} color="#555" borderColor="transparent" style={{}} />
          }
        </div>
      </div>


    </div>
  )
}