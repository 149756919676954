import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import { BigPlus, CustomEx, CustomEdit, CustomDelete } from '../svg/svg_icons';
import { NavButton } from '../components'; 
import { Form } from 'react-bootstrap'; 
import firebase from 'firebase';

const LiveLearning = (props) => {
  let [courseList, setCourseList] = useState([]);

  useEffect(()=>{
    let db = firebase.firestore();
    let docRef = db.collection(`campbell_compass`).doc(`admin_tools`);
    docRef.get().then(function(doc) {
      setCourseList(doc.data().liveLearning)
    }).catch(function(error) {
      console.log("Error getting document:", error);
    });
  },[])

  const handleAddCourse = () =>{
    props.setModalContent (
      <AddCourseModalContent wrapper={props.wrapper} screenSize={props.screen && props.screen.size} 
        showModal={props.showModal} setShowModal={props.setShowModal} setCourseList={setCourseList} setCourseList={setCourseList}
      />
    );
    props.setShowModal(true)
  }

  const handleEditCourse = (index, title, description, date, instructor) =>{
    props.setModalContent (
      <EditCourseModalContent wrapper={props.wrapper} screenSize={props.screen && props.screen.size} 
        showModal={props.showModal} setShowModal={props.setShowModal} setCourseList={setCourseList} setCourseList={setCourseList}
        index={index} title={title} description={description} date={date} instructor={instructor}
      />
    );
    props.setShowModal(true)
  }

  const handleDeleteCourse = (index, title, description) =>{
    props.setModalContent (
      <DeleteCourseModalContent wrapper={props.wrapper} screenSize={props.screen && props.screen.size} 
        setShowModal={props.setShowModal} setCourseList={setCourseList} setCourseList={setCourseList}
        index={index} title={title} description={description}
      />
    );
    props.setShowModal(true)
  }

  let tempArray = []
  for(let i = 0; i < courseList.length; ++i){
    tempArray.push( {index: i, course: courseList[i]} );
  }

  // console.log('tempArray', tempArray)
  let theList = tempArray.map((lineItem)=> 
    <div key={lineItem.index} style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: props.wrapper.width, borderBottom: '1px solid rgba(0,0,0,0.2)'}}> 
      <div style={{display: 'flex', flexDirection: 'column', padding: '15px 20px', width: '100%'}}>
        <div style={{padding: 0, color: '#555', width: '100%', marginRight: 0, display: 'flex',}}>
          {lineItem.course.title}
        </div>
        <div style={{fontSize: '90%', fontWeight: 500, padding: 0, color: '#444', width: '100%', marginRight: 0, display: 'flex',}}>
          {lineItem.course.description}
        </div>
        <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 5, fontSize: '80%', fonstWeight: 500}}>
          <div>
            {lineItem.course.date}
          </div>
          <div>
            {lineItem.course.instructor ? <div>Instructor: {lineItem.course.instructor}</div> : '' }
        </div>
        </div>
      </div>
      <div style={{display: 'flex'}}>
        <div style={{margin: '-7px 0px', padding: 10, cursor: 'pointer'}} 
          onClick={()=>handleEditCourse(lineItem.index, lineItem.course.title, lineItem.course.description, lineItem.course.date, lineItem.course.instructor)}>
          <CustomEdit size={28} backgroundColor="#e6e6e6" color="#666" borderColor="#999" />
        </div>
        <div style={{margin: '-7px 15px', padding: 10, cursor: 'pointer'}} 
          onClick={()=>handleDeleteCourse(lineItem.index, lineItem.course.title, lineItem.course.description, lineItem.course.date, lineItem.course.instructor)}>
          <CustomDelete size={28} backgroundColor="#e6e6e6" color="#666" borderColor="#999" />
        </div>
      </div>
    </div>
  )


  let db = firebase.firestore();

  /**  TEST if current user should see this page */
  let [userPermitted, setUserPermitted] = useState(false);
  let docRef = db.collection(`campbell_compass`).doc(`admin_permissions`);
  let tempResult = false;
  docRef.get()
  .then((doc) => {
    let data = doc.data();
    // console.log('data=', data);
    let userName = firebase.auth().currentUser.displayName;
    // for(let i = 0; i < data.editLibrary.length; ++i){
    //   if(data.editLibrary[i] === userName){
    //     tempResult = true;   
    //   }
    // }
    for(let i = 0; i < data.editLiveLearning.length; ++i){
      if(data.editLiveLearning[i] === userName){
        tempResult = true;   
      }
    }
    // for(let i = 0; i < data.editPermissions.length; ++i){
    //   if(data.editPermissions[i] === userName){
    //     tempResult = true;   
    //   }
    // }
    // for(let i = 0; i < data.editQuotes.length; ++i){
    //   if(data.editQuotes[i] === userName){
    //     tempResult = true;   
    //   }
    // }
    setUserPermitted(tempResult);
  }).catch((error) => {
    console.log('ERROR - ', error);
  });
  /** END OF TEST */


  

  return(
    <div style={{}}>
      {userPermitted ?
        <div  className="Phases-sidein-page" >
          <div className="Assess-description-block" 
            style={{fontSize: '100%', lineHeight: '1.4em', padding: '15px 25px 0px 25px', width: '100%', backgroundColor: '#f8f8f8', 
          }}>
            <div style={{width: '100%', textAlign: 'center', }}>
              Add, edit, and delete courses using the icons below.
            </div>
            <br />
          </div>
          <div style={{display: 'flex', flexDirection: 'column', alignItems : 'center', paddingTop: 0, 
            fontWeight: 600, color: '#5f5f5f', height: 'auto',  }}>
            <div style={{display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'space-between', padding: 0}}>
              {theList}
            </div>
            <div style={{margin: '10px 0 20px 0'}} onClick={()=> handleAddCourse()}>
              <NavButton size={60} >
                <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <BigPlus size={40} />
                </div>
              </NavButton> 
              <div style={{fontSize: '90%', color: '#a0a0a0', marginTop: 12}}>Add new course</div>
            </div>
          </div>
        </div>
      :
        <div style={{textAlign: 'center', marginTop: 50, fontWeight: 500, color: 'red'}}>
          <div>YOU DON'T HAVE ACCESS TO THIS PAGE</div>
        </div>
      }
  </div>
  )
}
export default LiveLearning;


export const AddCourseModalContent = (props) => {
  console.log('AddCourseModalContent - props ', props)
  // Define the controlled textarea component
  let [courseTitle, setCourseTitle] = useState('');
  const handleTitleChange = (e) => {
    let target = e.target;
    setCourseTitle(target.value)
    console.log('courseTitle')
  }

  let [courseDescription, setCourseDescription] = useState('');
  const handleDescriptionChange = (e) => {
    let target = e.target;
    setCourseDescription(target.value)
    console.log('courseDescription')
  }

  let [courseDate, setCourseDate] = useState('');
  const handleDateChange = (e) => {
    let target = e.target;
    setCourseDate(target.value)
  }

  let [courseInstructor, setCourseInstructor] = useState('');
  const handleInstructorChange = (e) => {
    let target = e.target;
    setCourseInstructor(target.value)
  }

  const handleCancel = () => {
    props.setShowModal(false); 
    setCourseDescription('')
  }

  const handleAdd = () => {
    console.log('Inside handleAdd: ', courseDescription)
    props.setShowModal(false)
    let courseList = []
    // Get or create Firestore document
    let db = firebase.firestore();
    let docRef = db.collection(`campbell_compass`).doc(`admin_tools`);
    docRef.get().then(function(doc) {
      console.log('Inside handleAddCourse', doc.data())
      if (doc && doc.exists) {
        //console.log('doc.data().liveLearning: ', doc.data().liveLearning );
        courseList = doc.data().liveLearning;
        if(!courseList){
          courseList= [{title: courseTitle, description: courseDescription, date: courseDate, instructor: courseInstructor}]; 
        }else{
          courseList.push( {title: courseTitle, description: courseDescription, date: courseDate, instructor: courseInstructor} )
        }
        console.log('courseList', courseList)
        docRef.set({ 
          liveLearning: courseList
        }, { merge: true })
        .then(()=>{
          setCourseTitle('');
          setCourseDescription('');
          setCourseDate(' ');
          setCourseInstructor(' ');
          props.setCourseList( courseList ); 
          console.log('SUCCESS: Course added or document created');
        }).catch((error)=> {
          console.log('Something went wrong with the document creation: ', error);
        })
      }
    }).catch(function(error) {
      console.log("Error getting document:", error);
    });
  }
  return(
    <div style={{display: 'flex', flexDirection: 'column', width: props.wrapper.width - 0, marginTop: 10}}>
      <div >
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <div className="OtherAssessment" 
            style={{display: 'flex', justifyContent: 'center', 
            position: 'relative', margin: '0px 0 0px 0', width: '100%'}}
          >
            <div style={{position: 'static', width: '100%', maxWidth: 500, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <input type="text" name="course-title" value={courseTitle} placeholder="Course title" style={{width: '100%', padding: '8px 12px', marginBottom: 15}}
                onChange={(e)=>handleTitleChange(e)} />
              <textarea rows={4} name="description" value={courseDescription} placeholder="Course description" style={{width: '100%', padding: '8px 12px'}} 
                onChange={(e)=>handleDescriptionChange(e)} />
              
              <div style={{display: 'flex', justifContent: 'space-between', width: '100%', margin: '15px 0'}}>
                <input type="date" value={courseDate} style={{width: '100%', marginRight: 7}} 
                  onChange={(e)=>handleDateChange(e)} />
                <input type="text" value={courseInstructor} placeholder="Instructor" style={{width: '100%', marginLeft: 7}} 
                  onChange={(e)=>handleInstructorChange(e)} />
              </div>
              
              <div style={{display: 'flex', justifyContent: 'space-around', marginTop: 15, fontSize: '120%', width: '100%'}}>
                <div style={{ color: '#808080', cursor: 'pointer'}} 
                  onClick={()=> handleCancel() }>
                  Cancel
                </div>
                <div style={{
                  color: `${courseTitle && courseDescription ? '#0090FF' : '#bbb' }`,
                  cursor: `${courseTitle && courseDescription ? 'pointer' : 'default' }`,
                }} 
                  onClick={()=> (courseTitle && courseDescription) ? handleAdd() : null   }>
                  Add the Course  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const EditCourseModalContent = (props) => {

  let [courseTitle, setCourseTitle] = useState('');
  const handleTitleChange = (e) => {
    let target = e.target;
    console.log('inside handleTitleChange - target.value : ', target.value )
    setCourseTitle(target.value)
  }

  let [courseDescription, setCourseDescription] = useState('');
  const handleDescriptionChange = (e) => {
    let target = e.target;
    // console.log('inside handleTitleChange - target.value : ', target.value )
    setCourseDescription(target.value)
  }

  
  let [courseDate, setCourseDate] = useState('');
  console.log('courseDate : ', courseDate)
  const handleDateChange = (e) => {
    let target = e.target;
    setCourseDate(target.value)
  }

  let [courseInstructor, setCourseInstructor] = useState('');
  const handleInstructorChange = (e) => {
    let target = e.target;
    setCourseInstructor(target.value)
  }

  // console.log('courseTitle state', courseTitle)
  
  useEffect(()=>{
    setCourseTitle(props.title)
    setCourseDescription(props.description)
    setCourseDate(props.date)
    setCourseInstructor(props.instructor)
  },[props])

  const handleCancel = () => {
    setCourseTitle('')
    setCourseDescription('')
    setCourseDate('')
    setCourseInstructor('')
    props.setShowModal(false)
  }

  const handleUpdate = (index) => {
    let db = firebase.firestore();
    let docRef = db.collection(`campbell_compass`).doc(`admin_tools`);
    let dataList = [];
    docRef.get()
    .then((doc)=>{
      if(doc && doc.exists){
        dataList = doc.data().liveLearning;

        dataList[index].title = courseTitle;
        dataList[index].description = courseDescription;
      dataList[index].date = courseDate;
        dataList[index].instructor = courseInstructor;
        props.setCourseList( dataList ); 
        console.log('dataList: ', dataList)

        docRef.set({
          liveLearning: dataList
        }, { merge: true })
        .then(()=>{
          console.log('Course list updated successfully.');
        }).catch((error)=> {
          console.log('Error in update assessment authorizations: ', error);
        })

      }
    }).catch((error)=> {
      console.log('The list is empty: ', error);
    })
    setCourseTitle('')
    setCourseDescription('')
    setCourseDate('')
    setCourseInstructor('')
    props.setShowModal(false)
  }

  return(
    <div>
      <input type="text" name="course-title" value={courseTitle} placeholder="Course title" style={{width: '100%', padding: '8px 12px', marginBottom: 15}}
        onChange={(e)=>handleTitleChange(e)} />
      <textarea rows={4} name="description" value={courseDescription} placeholder="Course description" style={{width: '100%', padding: '8px 12px'}} 
        onChange={(e)=>handleDescriptionChange(e)} />
      <div style={{display: 'flex', justifContent: 'space-between', width: '100%', margin: '15px 0'}}>
        <input type="date" value={courseDate} style={{width: '100%', marginRight: 7}} 
          onChange={(e)=>handleDateChange(e)} />
        <input type="text" value={courseInstructor} placeholder="Instructor" style={{width: '100%', marginLeft: 7}} 
          onChange={(e)=>handleInstructorChange(e)} />
      </div>
    
      <div style={{display: 'flex', justifyContent: 'space-around', marginTop: 15, fontSize: '120%', width: '100%'}}>
        <div style={{ color: '#808080', cursor: 'pointer'}} onClick={()=> handleCancel() }>
          Cancel
        </div>
        <div style={{
          color: `${courseTitle && courseDescription ? '#0090FF' : '#bbb' }`,
          cursor: `${courseTitle && courseDescription ? 'pointer' : 'default' }`,
        }} 
          onClick={()=> courseTitle && courseDescription ? handleUpdate(props.index) : null   }>
          Update  
        </div>
      </div>
    </div>
  )
}




export const DeleteCourseModalContent = (props) => {
  
  const handleDelete = (index) => {
    let db = firebase.firestore();
    let docRef = db.collection(`campbell_compass`).doc(`admin_tools`);
    let tempList = [];
    let editedList = [];
    docRef.get()
    .then((doc)=>{
      if(doc && doc.exists){
        tempList = doc.data().liveLearning;
        for(let i = 0; i < tempList.length; ++i){
          if(i != index ){
              editedList.push(tempList[i])
          }
        }
        docRef.set({
          liveLearning: editedList
        }, { merge: true })
        .then(()=>{
          console.log('Course list updated successfully.');
          props.setCourseList(editedList)
        }).catch((error)=> {
          console.log('Error in update assessment authorizations: ', error);
        })

      }
    }).catch((error)=> {
      console.log('The list is empty: ', error);
    })
    props.setShowModal(false);
  }

  return(
    <div style={{display: 'flex', flexDirection: 'column', width: props.wrapper.width - 0}}>
      <div style={{fontSize: '115%', color: '#666'}}>{props.title}</div>
      <div style={{fontSize: '95%', color: '#888'}}>{props.description}</div>
      <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 25, fontSize: '130%', width: '100%'}}>
        <div style={{ color: '#999999'}} onClick={()=> { props.setShowModal(false) } }>
          Cancel
        </div>
        <div style={{
          color: `#ff0000`,
          cursor: 'pointer',
        }} 
          onClick={(e)=> handleDelete(props.index)  }>
          Delete  
        </div>
      </div>
    </div>
  )
}