import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import { usePageTransitions, usePathSegments } from '../../../../../vinciToolbox';
import { NavBackIcon, BigPlus, BigMinus, PersonGray, GoldBall } from '../../../../svg/svg_icons';
import { NavButton } from '../../../../components'; 
import firebase from 'firebase';
import '../../../../../firebase-init';

export const CourseInfo=(props)=> {
  let segments = usePathSegments();
  let courseID = segments[3] && segments[3].split(':');
  courseID = courseID && courseID[1];
  courseID = courseID && courseID.replace(/%20/gm, " ");
  // console.log('courseID=', courseID);

  // console.log('props=', props)
  let pt = usePageTransitions('/course_info', 3)

  let transition = useSpring({
    transform: `translateX(${pt.animate ? 0 : props.screenSize.width}px)`,
    backgroundColor: '#e6e6e6', 
    position: 'absolute', top: 0, left: 0, 
    width: props.wrapper.width, 
    height: props.screenSize.height,
    backgroundColor: '#e6e6e6',
    display: pt.render ? 'block' : 'none'
  });

  let listTransition = useSpring({
    margin: '20px 25px 20px 25px',
    
  })

  let refSubpageHeader = useRef(null);

  let contentWrapperHeight = refSubpageHeader.current ? refSubpageHeader.current.offsetHeight : 0;

  // let [courseList, setCourseList] = useState([]);
  let [courseList, setCourseList] = useState([]);
  let [courseInfo, setCourseInfo] = useState({});
  let [userSubscribed, setUserSubscribed] = useState('false')

  // if(courseInfo){
  //   console.log('courseInfo=', courseInfo)
  // }


  let db = firebase.firestore();

  // useEffect(()=>{
  //   let docRef = db.collection(`campbell_compass`).doc(`admin_tools`);
  //   docRef.get().then(function(doc) {
  //     setCourseInfo(doc.data().liveLearning[courseID])
  //     setCourseList(doc.data().liveLearning);
  //   }).catch(function(error) {
  //     console.log("Error getting document:", error);
  //   });
  // },[props])

  var unsubscribe = null;
  const getRealtimeUpdates = () =>{
    unsubscribe = db.collection(`campbell_compass`).doc(`admin_tools`)
        .onSnapshot((doc)=>{
      if(doc && doc.exists) {
        setCourseInfo(doc.data().liveLearning[courseID])
        setCourseList(doc.data().liveLearning)
      }else{
        console.log('There was a onSnapshot() error')
      }
    })
  }
  useEffect(()=>{
    getRealtimeUpdates(); 
  },[props])

  if(!pt.render){
    // console.log('unsubscribe')
    if(unsubscribe){
      unsubscribe();
    }
  }

  // Determine whether currentUser is enrolled in the selected course
  let [enrolled, setEnrolled] = useState(false);
  let attendeesArray = courseInfo && courseInfo.attendees;
  let displayName = firebase.auth().currentUser.displayName;
  let userExists = false;
  if(attendeesArray && attendeesArray.indexOf( displayName ) !== -1 ){
    userExists = true
  }else{
    userExists = false
  }
  useEffect(()=> {
    setEnrolled(userExists);
  },[userExists])

  const handleUnenrollUser = () => {
    console.log('inside handleUnenroll me')
    if(pt.render){
      if(courseList[courseID].attendees && courseList[courseID].attendees.length){
        attendeesArray = courseList[courseID].attendees; // Get current list of attendees
      }else{
        attendeesArray = []; // Initialize the array
      }
    }
    console.log('attendeesArray - before: ', attendeesArray);
    let displayName = firebase.auth().currentUser.displayName
    const index = attendeesArray.indexOf( displayName );
    if (index > -1) {
      attendeesArray.splice(index, 1);
    }
    console.log('attendeesArray - after: ', attendeesArray);
    let courseListTemp = courseList;
    courseListTemp[courseID]['attendees'] = attendeesArray;
    setCourseList(courseListTemp)
    console.log('courseListTemp - after after: ', courseListTemp);

    let db = firebase.firestore();
    let docRef = db.collection(`campbell_compass`).doc(`admin_tools`);
    docRef.set({ 
      liveLearning: courseListTemp    // courseList
    },{ merge: true })
    .then((res)=>{ 
      setCourseList(courseListTemp)
      setEnrolled(false)
      console.log('SUCCESS - name successfully added to attendee list')
    }).catch(function(error) {
      console.log("Setting the updated course lilst failed:", error);
    });



    }

    const handleEnrollUser = () => {
      if(pt.render){
        
        if(courseList[courseID].attendees && courseList[courseID].attendees.length){
          attendeesArray = courseList[courseID].attendees; // Get current list of attendees
        }else{
          attendeesArray = []; // Initialize the array
        }
        // console.log('attendeesArray - before: ', attendeesArray);
        attendeesArray.push( firebase.auth().currentUser.displayName );
        // console.log('attendeesArray after: ', attendeesArray);

        // attendeesArray.push(firebase.auth().currentUser.displayName); // Add currentUser to the attendeesArray of this course
        let courseInfoTemp = courseInfo;
        courseInfoTemp['attendees'] = attendeesArray;
        setCourseInfo(courseInfoTemp);
        let courseListTemp = courseList;
        courseListTemp[courseID] = courseInfoTemp;
        setCourseList(courseListTemp)

        let db = firebase.firestore();
        let docRef = db.collection(`campbell_compass`).doc(`admin_tools`);
        docRef.set({ 
          liveLearning: courseListTemp    // courseList
        },{ merge: true })
        .then((res)=>{ 
          setCourseList(courseListTemp)
          setEnrolled(true)
          console.log('SUCCESS - name successfully added to attendee list')
        }).catch(function(error) {
          console.log("Setting the updated course lilst failed:", error);
        });
      }
    }

  // Add an index to the attendees map
  let renderedList = [];
  if(courseInfo && courseInfo.attendees){
    let theList = [];
    for(let i = 0; i < courseInfo.attendees.length; ++i){
      theList.push({id: i, name: courseInfo.attendees[i]})
    }

    renderedList = theList.map((signup) => 
      <div key={signup.id} style={{display: 'flex', flexDirection: 'column'}}>
        <div style={{display: 'flex', marginBottom: 10}}>
          <PersonGray size={25} name={`attendee-${signup.id}`} />
          <div style={{margin: '2px 0 0 15px'}}>
            {signup.name}
          </div>
        </div>
      </div>
    );
  }

  return(
    <Fragment>
        <div style={{backgroundColor: '#cccccc'}}>
          <animated.div  className="Phases-sidein-page" style={transition}>
          <div className="Phases-subpage-header">
            <div className="Phase-subpage-header-icon" onClick={()=>{ window.history.go(-1)} }>
              <NavButton variant="secondary" name="back" size={50} >
                <NavBackIcon height="50" name="course_entroll" />
              </NavButton> 
            </div>
            <div className="Phase-subpage-header-text" style={{margin: '0 auto'}}>
              Course Details
            </div>
          </div>
          <div className="Assess-description-block" 
            style={{minHeight: 100, fontSize: '100%', width: '100%', backgroundColor: '#f4f4f4', overflow: 'hidden'}}
          >

          <div style={{ padding: '15px 0 10px 0', fontWeight: 500}}>
          <div style={{fontSize: '115%', margin: '0 0 10px 0'}}>{courseInfo && courseInfo.title}</div>

            
          <div style={{ margin: '0 0 10px 0'}}>{courseInfo && courseInfo.description}</div>
          
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <div>Date: {courseInfo && courseInfo.date}</div>
          <div>Instructor: {courseInfo && courseInfo.instructor}</div>
          </div>


          </div>



          </div>
          <animated.div style={listTransition}>
            {renderedList}
          </animated.div>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <div style={{margin: '30px 0 20px 0'}} onClick={()=> enrolled ? handleUnenrollUser() : handleEnrollUser()}>
              <NavButton size={60} >
                <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', 
                }}>
                  <div style={{position: 'relative', top: -25, left: -25}}>
                    <div style={{position: 'absolute', top: 0, right: 0, bottom: 0, left: 0}}>
                      <GoldBall size={50} name="enroll" />
                    </div>
                    <div style={{position: 'absolute', top: 1, right: 0, bottom: 0, left: 1, margin: '7px 0 0 8px'}}>
                      {enrolled ? 
                        <BigMinus size={40} color='rgba(0,0,0,0.3)' /> 
                      : 
                        <BigPlus size={40} color='rgba(0,0,0,0.3)'  
                    />}
                    </div>
                  </div>
                </div>
              </NavButton> 
              <div style={{fontSize: '120%', fontWeight: 600, color: '#808080', marginTop: 12}}>
                {enrolled ? <div>Unenroll me</div> :  <div>Enroll me</div> }
              </div>
            </div>
          </div>
          </animated.div>
        </div>
    </Fragment>
  )
}