import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useSpring, animated } from 'react-spring';
import { usePageTransitions, usePathSegments, goHome } from '../../../vinciToolbox';
import { InfoIcon } from '../../svg/svg_icons';
import { Modal } from './Modal';
import firebase from 'firebase';
import { CCButton } from '../../components'
import './Assessment.css';
import './Buttons.css';
import '../Phase.css';
import { AutocompleteUsers } from '../../components';

let db = firebase.firestore(); // Creates database reference

export const Assess = (props)=> {
  // console.log('Assess - props: ', props)
  let headerHeight = props.headerHeight;
  let pt = usePageTransitions('/assess', 1)
  // let segment = usePathSegments(null);

  let transition = useSpring({
    transform: `translateY(${pt.animate ? headerHeight : props.screenSize.height}px)`,
    display: pt.render ? 'block' : 'none',
  });

  let [clear, setClear] = useState(false); // Clears the input field
  let [value, setValue] = useState(null); // clears the stored value value of the imput field
  let [showModal, setShowModal] = useState(false);
  let [showModal2, setShowModal2] = useState(false);


  // Create authorizations doc if it doesn't exist
  let userEmail = firebase.auth().currentUser.email;

  if(userEmail){
    let docRef = db.collection(`campbell_compass`).doc(userEmail + '_my_authorizations');
    docRef.get().then((doc) => {
      if(doc && doc.exists){
        // console.log('doc found: ', doc.data())
      }else{
        console.log('didn\'t find it');
        docRef.set({
          authorized: []
        }).then(() => {
          console.log('doc successfully created!')
        })
      }
    })
  }

  const beginAssessment = (rt, recip, closeModal) => {
    let docRef = db.doc('campbell_compass/users');
    docRef.get().then(function(doc) {
      if (doc && doc.exists) {
        let usersArray = Object.values(doc.data().users);
        for(let i = 0; i < usersArray.length; ++i){
          if(usersArray[i].displayName === recip){
            // console.log('user record found: ', usersArray[i]);
            // Check if currentUser is authorized to assess the recipient
            let docRef = db.collection(`campbell_compass`).doc(`${usersArray[i].email}_my_authorizations`);
            docRef.get().then(function(doc) {
              // Get user's authorization list
              if (doc && doc.exists) {
                let authArray = doc && doc.data().authorized;
                // Add props.user.displayName to the authArray because currentUser is always authorized to assess 
                // authArray.push(props && props.user.displayName); 
                // Push the recipient's manager name onto the list if the user IS that manager
                if(usersArray[i].managerName === props.user.displayName){
                  authArray.push(props && props.user.displayName);
                  console.log(`${props.user.displayName} is the manager of ${recip}`)
                }else{
                  console.log(`${props.user.displayName} is not the manager of ${recip}`)
                }
                console.log('recipient: ', recip);
                // Test if user is on the list
                let onTheList = false;
                // console.log('authArray 111111: ', authArray);

                if(authArray.length){
                  for(let x = 0; x < authArray.length; ++x){
                    // console.log('Inside loop - authArray[i]: ', authArray[x])
                    if(props.user && props.user.displayName === authArray[x]){
                      onTheList = true;
                    }                  
                  }
                }
                if(props.user && props.user.displayName === recip){
                  onTheList = true;
                } 
                // console.log('onTheList: ', onTheList)
                if(onTheList){
                  props.setSelectedUser(usersArray[i]);
                  props.rt.nav('/assess/do_assessment');
                  setShowModal2(false);
                }else{
                  console.log(`You are not authorized to assess ${usersArray[i].displayName}`);
                  setShowModal2(true);
                }
              }else{
                // console.log(`You are not authorized to assess ${usersArray[i].displayName}`);
                setShowModal2(true);
              }
            }).catch((error)=>{
              console.log('ERROR - authorization list not found: ', error);
            })
          }
        }
      } else {
        console.log("No such document!");
      }
    }).catch(function(error) {
      console.log('Critical Failure! The "users" document does not exist: ', error);
    }); 
    if(closeModal) setShowModal(false);
  }


  let [nameFound, setNameFound] = useState(false);
  
  useEffect(()=>{
    if(!value){
      // console.log('THE FIELD IS EMPTY')
      setNameFound(false)
    }else{
      // console.log('THE FIELD HAS STUFF IN IT')
    }
  })

  let [showInfo, setShowInfo] = useState(false);
  // console.log('showInfo ', showInfo)

  let infoTransition = useSpring({
    opacity: showInfo ? 1 : 0
  })


  const handleContinue = () => {
    // updateList(value, true); // Enable the blue Continue link
    beginAssessment(props.rt, value, true); 
    setClear(true) ;
    setShowModal2(false);
  }

  // console.log('######## width calc', props.wrapper.width / 550)

  return(
    <div>
      <animated.div style={transition}>
        <div style={{
          // overflowX: 'hidden', overflowY: 'auto', 
          position: 'absolute', width: props.wrapper.width, height: props.screenSize.height - headerHeight}}>
          <div>
            <div className="Phases-description-block" style={{backgroundColor: '#f4f4f4'}}>
              Assessment combines your self-assessment with peer and manager feedback for each leadership compass point under the nine competencies.  
              Assessments are optional.  You can complete the assessment, or move straight to the plan phase.
            </div>
            <div style= {{height: props.wrapper.style.height-200}}>
              <div style= {{
                display: 'flex', flexDirection: 'column', 
                alignContent: 'flex-start', justifyContent: 'space-around',
                margin: '20px 30px',
                fontSize: '100%',
                fontWeight: 600,
                color: '#777777',
                // border: '1px solid red',
                height: '90%',
                maxHeight: 350
              }}>
                <div className="Assess-button-wrapper"> 
                  <div onClick={()=> { setShowModal(true)}} >
                    <CCButton>Create or resume assessments</CCButton>
                  </div>
                </div>
                <div className="Assess-button-wrapper"> 
                  <div onClick={()=> props.rt.nav('/assess/authorizations')} >
                    <CCButton>Assessment authorizations</CCButton>
                  </div>
                </div>
                <div className="Assess-button-wrapper"> 
                  <div onClick={()=> props.rt.nav('/assess/view')} >
                    <CCButton>View assessments</CCButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </animated.div>

      <Modal show={showModal ? true : false} width={'90%' } >
          <div style={{display: 'flex', flexDirection: 'column', width: props.wrapper.width - 0, }}>
            <div style={{width: '100%', textAlign: 'center', fontSize: '120%', fontWeight: 600, color: '#606060', marginBottom: 10}}>
            Who is this assessment for?
          </div>
          <div >
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <div className="OtherAssessment" 
                style={{display: 'flex', justifyContent: 'center', 
                position: 'relative', margin: '0px 0 0px 0', width: '100%'}}
              >
                <div style={{position: 'static', width: '100%', maxWidth: 500, display: 'flex', justifyContent: 'center'}}>
                  <AutocompleteUsers value={value} setValue={setValue} 
                    clear={clear} setClear={setClear} nameFound={nameFound} setNameFound={setNameFound} 
                    setShowModal={setShowModal} handleContinue={handleContinue}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal show={showModal2} setShow={setShowModal2} width={'90%'}>
        <div style={{display: 'flex', flexDirection: 'column', width: props.wrapper.width - 0}}>
          <div >
            <div style={{color: '#444', fontSize: '110%', textAlign: 'center', lineHeight: '1.4em'}}>
            {value} has not authorized you to do this assessment.
            </div>
          </div>
          <div style={{display: 'flex', justifyContent: 'space-around', marginTop: 35, fontSize: '120%', width: '100%'}}>
            <div style={{color: "#F5B01B", cursor: 'pointer'}} onClick={()=> { setShowModal2(false); setValue(''); setClear(true)}}>
              Cancel Assessment
            </div>
          </div>
        </div>
      </Modal>

    </div>
  )
}




