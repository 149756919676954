import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import { usePageTransitions, usePathSegments } from '../../vinciToolbox';
import { BackBlueIcon, BigPlus, InfoIcon, CustomEx } from '../svg/svg_icons';
// import { CCButton } from '../Plan'
import { NavButton } from '../components'; 
// import { AutocompleteUsers } from '../../../components';
// import { Modal } from './Modal';

import firebase from 'firebase';

// import '../Phase.css';
// import '../Resources.css';

let db = firebase.firestore(); // Creates database reference

export const UserProfile = (props) => {
  let pt = usePageTransitions('/profile', 2)

  let segment = usePathSegments(null);


  let transition = useSpring({
    transform: `translateX(${pt.animate ? 0 : props.screenSize.width}px)`,
    backgroundColor: '#e6e6e6', 
    position: 'absolute', top: 0, left: 0, 
    width: props.wrapper.width, 
    height: props.screenSize.height,
    overflowX: 'hidden', overflowY: 'auto',
    backgroundColor: '#e6e6e6',
    display: pt.render ? 'block' : 'none'
  });

  return(
    <Fragment>
      <div style={{backgroundColor: '#cccccc'}}>
        <animated.div  className="Phases-sidein-page" style={transition}>
          <div className="Phases-subpage-header">
            <div className="Phase-subpage-header-icon" onClick={()=>{ window.history.go(-1)} }>
              <NavButton variant="secondary"  size={50} >
                <BackBlueIcon height="50" name="authorize-back"/>
              </NavButton> 
            </div>
            <div className="Phase-subpage-header-text" style={{margin: '0 auto'}}>{props.selectedUser}</div>
          </div>

          {/*<div className="Assess-description-block" 
            style={{minHeight: 75, fontSize: '100%', lineHeight: '1.4em', padding: '20px 25px 5px 25px',width: '100%', backgroundColor: '#f4f4f4', 
          }}>
            <div>
              The list below defines who is currently authorized to assess you. 
              When you receive each assessment, remove them from the list.
            </div>
            <br />
        </div>*/}
          <div style={{display: 'flex', flexDirection: 'column', alignItems : 'center', paddingTop: '5%', 
            fontWeight: 600, color: '#5f5f5f', height: 'auto', width: props.wrapper.width }}>


          </div>
        </animated.div>
      </div>

      {/*<Modal show={showModal ? true : false} width={'90%' } >
        <div style={{display: 'flex', flexDirection: 'column', width: props.wrapper.width - 0, marginTop: 10}}>
          <div >
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <div className="OtherAssessment" 
                style={{display: 'flex', justifyContent: 'center', 
                position: 'relative', margin: '0px 0 0px 0', width: '100%'}}
              >
                <div style={{position: 'static', width: '100%', maxWidth: 500, display: 'flex', justifyContent: 'center'}}>
                  <AutocompleteUsers value={value} setValue={setValue} 
                    clear={clear} setClear={setClear} nameFound={nameFound} setNameFound={setNameFound} 
                    setShowModal={setShowModal} handleContinue={handleContinue}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal show={thisPage==='/authorizations' && showDeleteModal ? true : false} width={'80%'}>
        <div style={{display: 'flex', flexDirection: 'column', width: props.wrapper.width - 0}}>
            <div style={{display: 'flex', justifyContent: 'center', fontSize: '130%', color: '#333333'}}>
              Remove {itemToDelete.name} ?
            </div>
          <div style={{display: 'flex', justifyContent: 'space-around', marginTop: 25, fontSize: '130%', width: '100%'}}>
            <div style={{ color: '#999999'}} onClick={()=> { setClear(true); setShowDeleteModal(false) } }>
              Cancel
            </div>
            <div style={{
              color: `#ff0000`,
              cursor: 'pointer',
            }} 
              onClick={()=> handleDeleteFromDB()  }>
              Delete  
            </div>
          </div>
        </div>
          </Modal> */}

    </Fragment>
  )
}

