

import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { usePathSegments } from '../../../../../../../../vinciToolbox';
import firebase from 'firebase';
import '../../../../../../../../firebase-init';



export const DeleteMenu = (props) => {

  let segment = usePathSegments();
  let p = segment[4] && segment[4].split(':');
  let t = segment[5] && segment[5].split(':');

  const handleRemove = (e, index) => {
    console.log(`index=${index}`, index)
    props.setMenu(false);
    let db = firebase.firestore();
    let docRef = {};
    docRef = db.doc(`campbell_compass/resource_library_${p[1]}_${p[2]}`); 
    //console.log('t[1]=', t[1])
    
    console.log('libraryContent=', props.libraryContents)
    docRef.get()
    .then((doc)=>{
      //console.log( 'doc=', doc && doc.data()[t[1]] );
      props.setLibraryContents( doc.data()[t[1]] );

      let editedArray = [];
      for(let i=0; i < doc.data()[t[1]].length; ++i){
        if(index != i){
          editedArray.push(doc.data()[t[1]][i])
        }
      }
      console.log( 'editedArray=', editedArray );
      let keyValue = t[1].toString()
      docRef.set({
      [keyValue]: editedArray
      }, { merge: true } )
      .then(function() {
        console.log('XREMOVE SUCCESS');
      })
      .catch(function(error) {
          console.error("Error writing document: ", error);
      });
    }).catch((error)=> {
      console.log('Document not found!: ', error);
    })



    // console.log('edited=', edited);
    // docRef.set({
    //   collection: edited
    // }).then(()=>{
    //   console.log('REMOVE SUCCESS');
    // }).catch((error)=> {
    //   console.log('you screwed up!: ', error);
    // })
  }





  let menuStyle = useSpring({
    config: {tension: 400},
    position: 'absolute', 
    width: 320, 
    // height: 100,
    top: -15, left: 20,
    padding: '15px 30px',
    backgroundColor: '#f4f4f4',
    transform: `scale(${props.menu ? 1 : 0.5},${props.menu ? 1 : 0.5})`,
    // opacity: props.menu ? .5 : 0,
    borderRadius: 10,
    boxShadow: '0 0 20px rgba(0,0,0,0.3)',
    color: '#444444',
    fontSize: '110%',
    userSelect: 'none',
    display: props.menu ? 'block' : 'none'
  })
  // Strip the 's' from the resource type
  let stripped = t[1].slice(0, -1)
  return(
    <animated.div style={menuStyle} >      
      <div style={{fontWeight: 500}}>
        <span>Remove this {stripped} from the Resource Library?</span>
      </div>
      <div style={{display: 'flex', justifyContent: 'space-between',
      margin: '15px 0 0 0',
      fontWeight: 500,
      fontSize: '110%',
    }}>
        <div style={{cursor: 'pointer'}} 
          onClick={(e)=>props.setMenu(false)}
        >
          Cancel
        </div>
        <div style={{ color: 'red', cursor: 'pointer'}} 
          onClick={(e)=>handleRemove(e, props.index)}
        >
          Remove
        </div>
      </div>
    </animated.div>
  )
}

