import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import firebase from 'firebase';
import '../../../../firebase-init.js'
import ReactHtmlParser from 'react-html-parser';
import { isSelfAssessment } from './DoAssessment';
import '../Assessment.css';
// import './MyAutosuggest.css';
var markdown = require('markdown-it')();

let db = firebase.firestore();

export const HeadingLine = (props) => {
  const headingStyle = useSpring({
    position: 'relative', left: 0, 
    transform: `translatex(${(-props.wrapper.width+30) * props.hPointer }px)`,
    width: (props.wrapper.width+30) * 3,
    // border: '2px solid green'
  });
  return(
    <animated.div className="Assess-data-block" style={headingStyle}>
      { 
        props.headingLine.map((h)=>
        <div key={h.key} className="Assess-line" style={{marginBottom: -30, display: 'flex', width: props.wrapper.width - 30}}>
          <div className="Assess-heading Assess-left-column" style={{fontSize: '120%'}} >
            {h.heading}
          </div>
          {/*<div className="Assess-right-column" style={{paddingTop: 17}} >
            ({h.count} of {h.total})
            </div>*/}
        </div>
        )
      }
    </animated.div>
  )
}

export const SubHeadingLine = (props) => {
  const subHeadingStyle = useSpring({
    position: 'relative', left: 0, 
    transform: `translatex(${(-props.wrapper.width+30) * props.shPointer }px)`, 
    width: (props.wrapper.width+30) * 9,
  });
  return(   
    <animated.div className="Assess-subheading Assess-data-block" style={subHeadingStyle}>
      {
        props.subHeadingLine.map((sh)=>
        <div key={sh.key}  style={{display: 'flex', margin: `0 0 15px 0`, fontWeight: 600, fontSize: '110%', width: props.wrapper.width - 30}}>
          <div className="Assess-left-column" style={{fontWeight: 500}} >
            {sh.subHeading}
          </div>
          {/*<div className="Assess-right-column">
            ({sh.count} of {sh.total})
            </div>*/}
        </div>
        )
      }
    </animated.div>
  )
}

export const CompassPointLine = (props) => {
  // if(props){
  //   console.log('Inside CompassPointLine - props=', props)
  // }
  useEffect(()=>{
    // console.log('CompassPointLine - props: ', props)
  },[props])

  const cPointStyle = useSpring({
    config: {tension: 300},
    position: 'relative', left: 0,
    transform: `translatex(${(-props.wrapper.width+30) * props.cpPointer }px)`, 
  });

  // console.log('props.cpPointer=', props.cpPointer)

  return(   
    <animated.div className="Assess-subheading Assess-data-block" style={cPointStyle} >
    {
      props.compassPointLine.map((cp)=>
      <div  key={cp.key} style={{display: 'flex', flexDirection: 'column', backgroundColor: '#e6e6e6', marginTop: 15,
        // boxSizing: 'border-box', border: '4px solid lightgreen', 
      }} >
        <div style={{display: 'flex', margin: `0 0 0 0`, paddingRight: 0, fontWeight: 600, fontSize: '110%', width: props.wrapper.width - 30,
        }}>
          <div style={{paddingLeft: 25, display: props.cpPointer === cp.key ? 'block' : 'none', 
            // border: '1px solid red'
          }}>
            <div className="Assess-left-column" style={{fontSize: '90%', color: '#555555', fontWeight: 500}} >
              {ReactHtmlParser(markdown.render( cp.compassPoint))}
            </div>
            {/*<div className="Assess-right-column" style={{marginTop: 15}}>
              ({cp.cpCount} of {cp.total})
              </div>*/}
          <div style={{marginRight: 0, marginBottom: 0}}>
            { props.user ?
              <BarGraph 
                cpKey={cp.key} 
                hCount={cp.hCount} shCount={cp.shCount} cpCount={cp.cpCount} 
                barValue={cp.barValue} 
                compassPointLine={props.compassPointLine} cpPointer={props.cpPointer} 
                ccDef={props.ccDef} setCcDef={()=>props.setCcDef()} 
                user={props.user} 
                assessmentNumber={props.assessmentNumber}
                // assessmentType={props.assessmentType}
                selectedUser={props.selectedUser}
                finishedButton={props.finishedButton}
                setFinishedButton={props.setFinishedButton}
                submitted={props.submitted}
                setSubmitted={props.setSubmitted}
                indexed={props.indexed}
                getNumberOfUnscoredPages={props.getNumberOfUnscoredPages}
                setShowModal5={props.setShowModal5}
              />
              : ''
            }
          </div>
          </div>
        </div>
      </div>
      )
    }
  </animated.div>
  )
}


const BarGraph = (props) => {
  // console.log('Inside BarGraph - props=', props)
  let ccDef = props.ccDef;
  let [barValue, setBarValue] = useState( 0 );
  let docRef = db.collection(`campbell_compass`).doc(`/${props.selectedUser.email}_my_assessments`);

  const handleNumberClick = (num) =>{
// console.log('props.selectedUser.email: ', props.selectedUser.email)
    setBarValue(num);
    ccDef[props.hCount-1].subHeading[props.shCount-1].compassPoint[props.cpCount-1].barValue = num;
    let assessmentArray = [];

    // console.log(`hCount: ${props.hCount-1} - shCount: ${props.shCount-1} - cpCount: ${props.cpCount-1} - `)
    if(props.hCount === 3 && props.shCount === 3 && props.cpCount === 5 && !props.submitted){
      console.log('POPUP TIME!!!!', );
      props.setFinishedButton(true);
    }

    if(props.selectedUser.email){
      db.collection(`campbell_compass`).doc(`${props.selectedUser.email}_my_assessments`);
      docRef.get()
      .then((doc)=>{
        if(doc && doc.exists){
          assessmentArray = doc.data().assessmentArray;
          // assessmentArray[assessmentArray.length-1].assessment = ccDef
          console.log('Inside handleNumberClick - props.indexed=', props.indexed);
          if(assessmentArray[props.indexed]){
            assessmentArray[props.indexed].assessment = ccDef;
          }
          // console.log('assessmentArray=', assessmentArray);
    
          docRef.set({
            assessmentArray
          })
          .then(()=>{
            console.log('SUCCESS! You updated the bar value');
            let unscored = props.getNumberOfUnscoredPages();
            if(unscored === 0) props.setShowModal5(true);
          })
          .catch((error)=> {
            console.log('The barValue update didn\'t happen: ', error);
          })
        }
      }).catch((error)=> {
        console.log('Something went wrong: ', error);
      });
    }
  }
  // console.log(`props.cpPointer: ${props.cpPointer}, props.cpKey: ${props.cpKey}`)
  return(
    <div style={{height: 100, position: 'relative', display: 'flex', flexDirection: 'column', 
      alignContent: 'flex-start', paddingRight: 30, width: '100%'}}
    >

      { (props.cpPointer>=props.cpKey-2) && (props.cpPointer<=props.cpKey+2) ?
        <React.Fragment>
        
          <div className="SelfAssessment-outer-bar" style={{position: 'absolute'}}>
            <Bar value={props.barValue} // assessmentType={props.assessmentType}
              cpPointer={props.cpPointer} compassPointLine={props.compassPointLine} ccDef={props.ccDef}
              selectedUser={props.selectedUser}
              submitted={props.submitted}
              setSubmitted={props.setSubmitted}
              indexed={props.indexed}
              />
          </div>
          <div className="SelfAssessment-bar-numbers"  style={{position: 'absolute'}}>
            <BarButton label={1} handleNumberClick={()=>handleNumberClick(1)} user={props.user} />
            <NumberDivider />
            <BarButton label={2} handleNumberClick={()=>handleNumberClick(2)} user={props.user} />
            <NumberDivider />
            <BarButton label={3} handleNumberClick={()=>handleNumberClick(3)} user={props.user} />
            <NumberDivider />
            <BarButton label={4} handleNumberClick={()=>handleNumberClick(4)} user={props.user} />
            <NumberDivider />
            <BarButton label={5} handleNumberClick={()=>handleNumberClick(5)} user={props.user} />
          </div>
        </React.Fragment>
        :
        ''
      }
    </div>
  )
}

const NumberDivider = () => {
  return <div style={{marginTop: 70, opacity: 0.35}}>|</div>
}

const Bar = (props) => {
  // console.log('Bar-props: ', props)
  const barStyle = useSpring({
    width: `${props.value * .1 * 200}%`,
    backgroundImage: `${ isSelfAssessment(props.selectedUser) ? 'linear-gradient(to right, #F5B01B , #F9C908)' : 
    'linear-gradient(to right, #3fafea , #0cceeb)'}` 
  })
  return(
    <Fragment>
    <animated.div className="Assessment-inner-bar" style={barStyle}></animated.div>
    </Fragment>
  )
}

const BarButton = (props) => {
  return(
    <div onClick={()=>props.handleNumberClick(props.label)} 
      style={{cursor: 'pointer', height: 100, width: '100%', 
        display: 'flex', flexDirection: 'column', justifyContent: 'flex-end',
      }}>
      <div style={{textAlign: 'center'}}>{(props.label)}</div>
    </div>
  )
}