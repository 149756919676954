import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import { BigPlus, CustomDelete } from '../svg/svg_icons';
import { NavButton } from '../components'; 
import { Form } from 'react-bootstrap'; 
import firebase from 'firebase';


const QuoteOfTheDay = (props) => {
  let [quoteList, setQuoteList] = useState([]);

  useEffect(()=>{
    let db = firebase.firestore();
    let docRef = db.collection(`campbell_compass`).doc(`admin_tools`);
    docRef.get()
    .then(function(doc) {
      if(doc && doc.data()){
        setQuoteList(doc.data().quoteOfTheDay);
      }else{ // DOC DOES NOT EXIST SO CREATED IT
        console.log('THIS DOC DOES NOT EXIST SO CREATED IT')
        docRef.set({
          quoteOfTheDay: []
        }, { merge: true })
        .then(() => {
          setQuoteList(doc.data().quoteOfTheDay);
          console.log('SUCCESS - quoteOfTheDay field was created')
        })
      }
    }).catch(function(error) {
      console.log("Error getting document:", error);
    });
  },[])

  const handleAddQuote = () =>{
    props.setModalContent (
      <AddQuoteModalContent wrapper={props.wrapper} screenSize={props.screen && props.screen.size} 
        setShowModal={props.setShowModal} setQuoteList={setQuoteList} setQuoteList={setQuoteList}
      />
    );
    props.setShowModal(true)
  }

  const handleDeleteQuote = (index, name) =>{
    props.setModalContent (
      <DeleteQuoteModalContent wrapper={props.wrapper} screenSize={props.screen && props.screen.size} 
        setShowModal={props.setShowModal} setQuoteList={setQuoteList} setQuoteList={setQuoteList}
        index={index} name={name}
      />
    );
    props.setShowModal(true)
  }


  let db = firebase.firestore();

  /**  TEST if current user should see this page */
  let [userPermitted, setUserPermitted] = useState(false);
  let docRef = db.collection(`campbell_compass`).doc(`admin_permissions`);
  let tempResult = false;
  docRef.get()
  .then((doc) => {
    let data = doc.data();
    // console.log('data=', data);
    let userName = firebase.auth().currentUser.displayName;
    // for(let i = 0; i < data.editLibrary.length; ++i){
    //   if(data.editLibrary[i] === userName){
    //     tempResult = true;   
    //   }
    // }
    // for(let i = 0; i < data.editLiveLearning.length; ++i){
    //   if(data.editLiveLearning[i] === userName){
    //     tempResult = true;   
    //   }
    // }
    // for(let i = 0; i < data.editPermissions.length; ++i){
    //   if(data.editPermissions[i] === userName){
    //     tempResult = true;   
    //   }
    // }
    for(let i = 0; i < data.editQuotes.length; ++i){
      if(data.editQuotes[i] === userName){
        tempResult = true;   
      }
    }
    setUserPermitted(tempResult);
  }).catch((error) => {
    console.log('ERROR - ', error);
  });
  /** END OF TEST */




  let tempArray = []
  if(quoteList && quoteList.length){
    for(let i = 0; i < quoteList.length; ++i){
      tempArray.push( {index: i, name: quoteList[i]} );
    }
  }
  let theList = tempArray.map((lineItem)=> 
    <div key={lineItem.index} style={{display: 'flex',  alignItems: 'center', justifyContent: 'space-between', width: props.wrapper.width, borderBottom: '1px solid rgba(0,0,0,0.2)'}}> 
      <div style={{padding: '15px 20px ', color: '#555', width: '100%', marginRight: 0, display: 'flex'}}>
        {lineItem.name}
      </div>
      <div style={{margin: '-7px 15px', padding: 10, cursor: 'pointer'}} onClick={()=>handleDeleteQuote(lineItem.index, lineItem.name)}>
        <CustomDelete size={28} backgroundColor="#e6e6e6" color="#666" borderColor="#999"  />
      </div>
    </div> 
  )

  return(
    <div style={{}}>
    {userPermitted ? 
    <div  className="Phases-sidein-page" >
      <div className="Assess-description-block" 
        style={{minHeight: 75, fontSize: '100%', lineHeight: '1.4em', padding: '20px 25px 5px 25px',width: '100%', backgroundColor: '#f2f2f2', 
      }}>
        <div>
          Below are the quotes that appear on the Home Screen. Add or delete them as desired. 
        </div>
        <br />
      </div>
      <div style={{display: 'flex', flexDirection: 'column', alignItems : 'center', paddingTop: 0, 
        fontWeight: 600, color: '#5f5f5f', height: 'auto',  }}>
        <div style={{display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'space-between', padding: 0}}>
          {theList}
        </div>
        <div style={{margin: '10px 0 20px 0'}} onClick={()=> handleAddQuote()}>
          <NavButton size={60} >
            <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <BigPlus size={40} />
            </div>
          </NavButton> 
          <div style={{fontSize: '90%', color: '#a0a0a0', marginTop: 12}}>Add new quote</div>
        </div>
      </div>
    </div>
    :
      <div style={{textAlign: 'center', marginTop: 50, fontWeight: 500, color: 'red'}}>
        <div>YOU DON'T HAVE ACCESS TO THIS PAGE</div>
      </div>
    }
  </div>
  )
}
export default QuoteOfTheDay;


export const AddQuoteModalContent = (props) => {
  // Define the controlled textarea component
  let [quote, setQuote] = useState('');
  const handleChange = (e) => {
    let target = e.target;
    setQuote(target.value)
  }

  const handleCancel = () => {
    props.setShowModal(false); 
    setQuote('')
  }

  const handleAdd = () => {
    console.log('Inside handleAdd: ', quote)
    props.setShowModal(false)
    let quoteList = ''
    // Get or create Firestore document
    let db = firebase.firestore();
    let docRef = db.collection(`campbell_compass`).doc(`admin_tools`);
    docRef.get().then(function(doc) {
      console.log('Inside handleAddQuote', doc.data())
      if (doc && doc.exists) {
        quoteList = doc.data().quoteOfTheDay;
        if(!quoteList){
          quoteList= [quote]; 
        }else{
          quoteList.push( quote )
        }        
        docRef.set({ 
          quoteOfTheDay: quoteList
        }, { merge: true })
        .then(()=>{

          props.setQuoteList( quoteList ); 
          console.log('SUCCESS: Quote added or document created');
          setQuote('')
        }).catch((error)=> {
          console.log('Something went wrong with the document creation: ', error);
        })
      }
    }).catch(function(error) {
      console.log("Error getting document:", error);
    });
  }
  return(
    <div style={{display: 'flex', flexDirection: 'column', width: props.wrapper.width - 0, marginTop: 10}}>
      <div >
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <div className="OtherAssessment" 
            style={{display: 'flex', justifyContent: 'center', 
            position: 'relative', margin: '0px 0 0px 0', width: '100%'}}
          >
            <div style={{position: 'static', width: '100%', maxWidth: 500, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <textarea rows={4} style={{width: '100%'}} value={quote} onChange={(e)=>handleChange(e)} />
              <div style={{display: 'flex', justifyContent: 'space-around', marginTop: 15, fontSize: '120%', width: '100%'}}>
                <div style={{ color: '#999999', cursor: 'pointer'}} onClick={()=> handleCancel() }>
                  Cancel
                </div>
                <div style={{
                  color: `${quote ? '#0090FF' : '#bbb' }`,
                  cursor: `${quote ? 'pointer' : 'default' }`,
                }} 
                  onClick={()=> quote ? handleAdd() : null   }>
                  Add the Quote  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const DeleteQuoteModalContent = (props) => {
  
  const handleDelete = (index) => {
    let db = firebase.firestore();
    let docRef = db.collection(`campbell_compass`).doc(`admin_tools`);
    let tempList = [];
    let editedList = [];
    docRef.get()
    .then((doc)=>{
      if(doc && doc.exists){
        tempList = doc.data().quoteOfTheDay;
        for(let i = 0; i < tempList.length; ++i){
          if(i != index ){
              editedList.push(tempList[i])
          }
        }
        docRef.set({
          quoteOfTheDay: editedList
        }, { merge: true })
        .then(()=>{
          console.log('Quote list updated successfully.');
          props.setQuoteList(editedList)
        }).catch((error)=> {
          console.log('Error in update assessment authorizations: ', error);
        })

      }
    }).catch((error)=> {
      console.log('The list is empty: ', error);
    })
    props.setShowModal(false);
  }

  return(
    <div style={{display: 'flex', flexDirection: 'column', width: props.wrapper.width - 0}}>
      <div>{props.name}</div>
      <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 25, fontSize: '130%', width: '100%'}}>
        <div style={{ color: '#999999'}} onClick={()=> { props.setShowModal(false) } }>
          Cancel
        </div>
        <div style={{
          color: `#ff0000`,
          cursor: 'pointer',
        }} 
          onClick={(e)=> handleDelete(props.index)  }>
          Delete  
        </div>
      </div>
    </div>
  )
}