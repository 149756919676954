import React, {useState, useEffect, Fragment} from 'react';
import { CustomCheck, CustomDelete, CustomNotes, ChallengingAssignmentIcon,  LiveTrainingIcon } from '../../svg/svg_icons';
import { Modal } from '../AssessPages/Modal';
import firebase from 'firebase';

var arraySort = require('array-sort');

let db = firebase.firestore();

const CompletionLog = (props) => {
  // console.log('Completion Log - props: ', props)

  let [completedLog, setCompletedLog] = useState([]);
  // console.log('completedLog = ', completedLog)

  // Create _completed doc if it doesn't exist
  let userEmail = firebase.auth().currentUser.email;

  if(userEmail){
    let docRef = db.collection(`campbell_compass`).doc(userEmail + '_completed');
    docRef.get().then((doc) => {
      if(doc && doc.exists){
        // console.log('doc found: ', doc.data())
      }else{
        console.log('didn\'t find it');
        docRef.set({
          completed: []
        }).then(() => {
          console.log('doc successfully created!')
        })
      }
    })
  }

  let unsubscribe = null;
  useEffect(()=>{
    const getRealtimeUpdates = () =>{
      unsubscribe = db.collection(`campbell_compass`).doc(`${firebase.auth().currentUser.email}_completed`)
      .onSnapshot((doc)=>{
        if(doc && doc.exists) {
          let tempArray = doc.data().completed;
          // console.log('tempArray=',tempArray);
          tempArray = arraySort(tempArray, 'timestamp', {reverse: true})
          setCompletedLog(tempArray)
        }else{
          console.log('onSnapshot error happened')
        }
      }, (error)=> {
        console.log('error=', error);
        // return( null );
      });
    }
    getRealtimeUpdates();  
  },[]);
  if(!props.pt.render, props){
    if(unsubscribe){
      unsubscribe();
    }
  }

  const ResourceType = (props) => {
    switch(props.type) {
      case 'books':
        return 'Book'
        break;
      case 'videos':
        return 'Video'
        break;
      case 'weblinks':
        return 'Website'
        break;
      case 'podcasts':
        return 'Podcast'
        break;
      case 'course':
        return 'Live Learning'
        break;
      case 'challenging_assignment':
        return 'Challenging Assignment'
        break;
      default:
        return null
    }
  }

  for(let i = 0; i < completedLog.length; ++i){
    completedLog[i]['id'] = i
  }

  const handleDelete = (index) => {
    let docRef = db.collection(`campbell_compass`).doc(userEmail + '_completed');
    let editedList = [];
    docRef.get().then((doc) => {
      if(doc && doc.exists){
        console.log('doc found: ', doc.data());
        let tempArray = doc.data().completed;
        for(let i = 0; i < tempArray.length; ++i){
          if(index != i){
            editedList.push(tempArray[i]);
          }
          editedList = arraySort(editedList, 'timestamp', {reverse: true})
        }
        console.log('editedList=', editedList)
        docRef.set({
          completed: editedList
        }).then(() => {
          console.log('doc successfully deleted!');
          setCompletedLog(editedList)
        }).catch((error) => {
          console.log('doc deletion failed!: ', error)
        })
      }
    })
    props.setShowModal(false);
  }

  const handleDeleteClicked = (i, title) => {
    props.setShowModal(true);
    // let target = e.target;
    console.log('target=', title);
    props.setModalContent(
      <div style={{minWidth: 250, fontWeight: 500, fontSize: '110%', textAlign: 'center'}}>
        {/*<div style={{margin: '0px 0 25px 0', color: 'tomato', textAlign: 'center'}}>You are about to delete:</div>*/}
        <div style={{fontWeight: 600}}>{title}</div>
        <div style={{display: 'flex', justifyContent: 'space-around', fontSize: '125%', fontWeight: 600, margin: '20px 0 0 0'}}>
          <div onClick={() => props.setShowModal(false)}>Cancel</div>
          <div onClick={() => handleDelete(i) } style={{color: 'tomato'}}>Delete</div>
        </div>

      </div>
    );
  }

  // if(completedLog.length){
  //   console.log('completedLog=', completedLog)
  // }

  let completedList = completedLog.map((lg) => 
    <div key={lg.id} style={{margin: '0 0 15px 0', paddingBottom: 15, borderBottom: '2px solid #cccccc'}}>
      <div style={{display: 'flex', justifyContent: 'flex-start'}}>
        
        <div style={{width: 75, display: 'flex', justifyContent: 'center', paddingTop: 7}}>
          {lg.type === 'course' ?
            <LiveTrainingIcon size={60} color="#F5B01B" />
            : ''
          }
          {lg.type === 'challenging_assignment' ?
            <ChallengingAssignmentIcon size={70} color="#F5B01B" />
            : ''
          }

          {lg.type === 'books' || 'videos' || 'weblinks' || 'podcasts' ?
            <div>
              <img src={lg.image} style={{width: '100%', height: 'auto'}} />
            </div>
            : ''
          }


        </div>
        
        <div style={{display: 'flex', flexDirection: 'column', width: '100%',}} >
          <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', margin: '3px 0', color: '#000', fontSize: '90%', fontWeight: 500,
            // border: '1px solid red'
          }}>
            <div style={{marginLeft: 18, color: '#000'}}>
              <span style={{marginRight: 3}}>{lg.timestamp}</span> -<span style={{fontWeight: 600, marginLeft: 5}}><ResourceType type={lg.type} /></span> 
            </div>
            <div   style={{marginTop: -5, padding: 5, cursor: 'pointer'}}>
              <div style={{display: 'flex'}}>
                <div onClick={(e)=> handleDeleteClicked(lg.id, lg.title)} style={{width: 25}}>
                  <CustomDelete size={26} backgroundColor="#e6e6e6" color="#888" borderColor="#999" />
                </div>
                {/*
                <div onClick={(e)=> null} style={{width: 45}}>
                  <CustomNotes size={26} backgroundColor="#e6e6e6" color="#444" borderColor="#999" />
                </div>
                */}
              </div>
            </div>
          </div>
            { lg.type === 'books' || lg.type === 'videos' || lg.type === 'weblinks' || lg.type === 'podcasts' ?
              <div style={{ cursor: 'pointer'}}  onClick={(e)=> { window.open(lg.url, "_blank")} } >
                <div style={{fontWeight: 600, fontSize: '110%', margin: '-7px 0 5px 15px'}}>
                  {lg.title}
                </div>
                <div style={{fontWeight: 500, marginLeft: 15}}>
                  {lg.type === 'challenging_assignment' ? lg.summary :  lg.description}
                </div>
              </div>
              : 
              <div>
                <div style={{fontWeight: 600, fontSize: '110%', margin: '-7px 0 5px 15px'}}>
                  {lg.title}
                </div>
                <div style={{fontWeight: 500, marginLeft: 15}}>
                  {lg.type === 'challenging_assignment' ? lg.summary :  lg.description}
                </div>
              </div>
            }
        </div>
      </div>
    </div>
  )
  

  useEffect(() => {

  },[])


  return(
    <Fragment>
    <div style={{height: props.screenSize.height - 135, padding: '20px 25px 25px 25px' , overflowX: 'hidden', overflowY: 'auto'}}>
      {completedList}
    </div>
    </Fragment>
  )

}

export default CompletionLog;
