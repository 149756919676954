
import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import { DeleteIcon, CustomDelete, CustomEdit   } from '../../../../svg/svg_icons';
import { Stars } from './Stars';
import firebase from 'firebase';
import '../../../../../firebase-init';
// import { usePathSegments } from '../../../../../vinciToolbox';
import extractDomain from 'extract-domain';

var sizeOf = require('image-size');

export const OneCollectionResource = React.memo(props=> {

  let [collectionContents, setCollectionContents] = useState([{collection: 'Collection Empty'}]);
  
  // useEffect(()=>{
  //   console.log('....................props=', props)
  // },[props])

  useEffect(()=>{
    if(props.user) {
    let db = firebase.firestore();
    let docRef = {};
    docRef = db.doc(`campbell_compass/${props.user && props.user.email}_collection`); 
      const getRealtimeUpdates = () =>{
        docRef.onSnapshot((doc)=>{
          
          if(doc && doc.exists) {
            // console.log('Save data: ', doc.data())
            setCollectionContents(doc.data().collection)
            return( null );
          }
        }, 
          (error)=> {
            console.log('error=', error);
            return( null );
          });
        }
        getRealtimeUpdates();  
      }
    },[props.user])
   
    let [menu, setMenu] = useState(false);

    const handleDelete = (e) => {
      setMenu(true);
      console.log('menu=', menu)
    }
   
    const imageRef = useRef(null);
    const handleSize = (image) => {
      if(image.naturalHeight > image.naturalWidth){
        image.style.width = '90px'
      }
    }

    // Extract domain from url to show the source of the article
    let [domain, setDomain] = useState('');
    useEffect(()=> {
      setDomain(extractDomain(props.url))
    },[])
    
    return(
      <div style={{display: 'flex', alignItems: 'flex-start', margin: 0, 
        borderBottom: '1px solid rgba(0,0,0,0.15)',
        borderTop: '1px solid #f4f4f4',
        position: 'relative',
      }}>
        <div style={{ width: 40, height: 45, margin: props.type==='books' ? '10px -3px 0 0' : '10px -3px 0 0', 
          position: 'absolute', top: 0, left: 13
        }} >
          <div  onClick={(e)=>setMenu(true)} style={{padding: 10, cursor: 'pointer'}}>
          {/*<DeleteIcon size={25} />*/}
          <CustomDelete size={27} backgroundColor="#e6e6e6" color="#666" borderColor="#999" />
        </div>
          <DeleteMenu menu={menu} setMenu={setMenu} index={props.index} collectionContents={collectionContents} user={props.user}/>
        </div>
        <div style={{margin: '22px 0 15px 30px', display: 'flex', cursor: 'pointer', overflowX: 'hidden' }} >
          {props.type==='books' ?
            <Fragment>
              <div style={{height: 'auto'}}>
                <img src={props.image} style={{ 
                  minHeight: 75, width: 'auto', width: 130,
                  boxShadow: '0px 2px 5px rgba(0,0,0,0.3)',
                  backgroundColor: '#c6c6c6',
                  marginTop: 5,
                  // border: 'red' // '1px solid rgba(0,0,0,0.2)'
                }}
                ref={imageRef}
                onLoad={()=> handleSize(imageRef.current)}
                />
              </div>
              <div style={{display: 'flex', width: '100%', flexDirection: 'row', marginLeft: -10 }} >
                <div style={{margin: '0 0px 0 10px', alignContent: 'stretch', overflow: 'auto'}} onClick={(e)=> { window.open(props.url, "_blank")} }>
                  <div style={{margin: '0 10px 4px 0px', fontSize: '90%', fontWeight: 400, color: '#55555'}}>
                    <div style={{margin: '0 20px 4px 15px', fontSize: '90%', fontWeight: 400, color: '#55555',}}>
                      <div style={{display: 'flex', justifyContent: 'space-between', 
                      // border: '1px solid blue'
                    }}>
                        <div style={{overflow: 'hidden', overflowWrap: 'no-wrap', textOverflow: 'ellipsis', 
                          
                        }} >
                          {domain}
                        </div>
                        {/*<div style={{display: 'flex', justifyContent: 'flex-end', width: 100,}}>
                          <Stars rating={props.rating} />
                        </div>*/}
                      </div>
                    </div>
                  </div>
                  <div style={{margin: '0 35px 0 15px', fontWeight: 500, color: '#222'}}>
                    {props.title}
                  </div>
                  <div style={{
                    margin: '5px 35px 8px 15px', 
                    fontSize: '85%', fonrWeight: 400, color: '#222222', 
                    overflow: 'hidden',
                    overflowWrap: 'break-word',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 4,
                    WebkitBoxOrient: 'vertical',
                  }}>{props.description}</div>
                </div>
              </div>
            </Fragment>
          :
            <Fragment>
              <div style={{height: 'auto'}}>
                <img src={props.image} style={{ 
                    minHeight: 75, width: 'auto', minWidth: 130, maxWidth: 130,
                    boxShadow: '0px 2px 5px rgba(0,0,0,0.3)',
                    backgroundColor: '#c6c6c6',
                    marginTop: 5
                  }}
                  ref={imageRef}
                  onLoad={()=> handleSize(imageRef.current)}
                />
              </div>
              <div style={{display: 'flex', width: '100%', flexDirection: 'row', margin: '-4px 0 0 0', overflowX: 'hidden', }} >
                <div style={{margin: '0 0px 0 5px', alignContent: 'stretch', overflow: 'auto'}} onClick={(e)=> { window.open(props.url, "_blank")} }>
                  <div style={{margin: '0 20px 4px 15px', fontSize: '90%', fontWeight: 400, color: '#55555'}}>
                    <div style={{display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{overflow: 'hidden', overflowWrap: 'no-wrap', textOverflow: 'ellipsis', }} >
                        {domain}
                      </div>
                      {/*<div style={{display: 'flex', justifyContent: 'flex-end', width: 100,}}>
                        <Stars rating={props.rating} />
                      </div>*/}
                    </div>
                  </div>
                  <div style={{margin: '0 20px 0 15px', fontWeight: 500, color: '#222'}}>
                    {props.title}
                  </div>
                  <div style={{
                    margin: '5px 25px 8px 15px', 
                    fontSize: '85%', fonrWeight: 400, color: '#222222', 
                    overflow: 'hidden',
                    overflowWrap: 'break-word',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 4,
                    WebkitBoxOrient: 'vertical',
                  }}>{props.description}</div>
                </div>
              </div>
            </Fragment>           
          }
        </div>
      </div>
    )
  });


  const DeleteMenu = (props) => {

    let menuStyle = useSpring({
      config: {tension: 400},
      position: 'relative', 
      width: 320, 
      // height: 100,
      top: -15, left: 20,
      padding: '15px 30px',
      backgroundColor: '#f4f4f4',
      transform: `scale(${props.menu ? 1 : 0.5},${props.menu ? 1 : 0.5})`,
      // opacity: props.menu ? .5 : 0,
      borderRadius: 10,
      boxShadow: '0 0 20px rgba(0,0,0,0.3)',
      color: '#444444',
      fontSize: '110%',
      userSelect: 'none',
      display: props.menu ? 'block' : 'none'
    })   

    const handleRemove = (e, index) => {
      // console.log(`index=${index}`, props.collectionContents)
      let edited = props.collectionContents;
      console.log('edited 1 : ', edited)
      props.setMenu(false);
      edited.splice(index, 1);
      console.log('edited 2 =', edited);
      let db = firebase.firestore();
      let docRef = {};
      docRef = db.doc(`campbell_compass/${props.user && props.user.email}_collection`); 
      console.log('edited 3 =', edited);
      docRef.set({
        collection: edited
      }).then(()=>{
        console.log('REMOVE SUCCESS');
      }).catch((error)=> {
        console.log('you screwed up!: ', error);
      })
    }


    return(
      <animated.div style={menuStyle} >      
        <div style={{fontWeight: 500}}>
          Remove this item from your Saved Resources?
        </div>
        <div style={{display: 'flex', justifyContent: 'space-between',
        margin: '15px 0 0 0',
        fontWeight: 500,
        fontSize: '110%',
      }}>
          <div style={{cursor: 'pointer'}} 
            onClick={(e)=>props.setMenu(false)}
          >
            Cancel
          </div>
          <div style={{ color: 'red', cursor: 'pointer'}} 
            onClick={(e)=>handleRemove(e, props.index)}
          >
            Remove
          </div>
        </div>
      </animated.div>
    )
  }