import React, { useState, useEffect, Fragment, useRef } from 'react';
import { StarColored } from '../../../../svg/svg_icons';

export const Stars = (props) => {
  let size = 14;
  let ratingArray = [];

  for(let i = 0; i < 5; ++i){
    ratingArray.push({index: i, rating: props.rating});
  }
  // console.log('ratingArray ', ratingArray)
  let rating = ratingArray.map((r)=> 
    <StarColored key={r.index} size={size} fillstar={props.rating > r.index ? 'yes' : 'no'} />
  );
  return(
    <div style={{
      display: 'flex'
      // border: '2px solid red'
    }}>
      {rating}
    </div>
  )
}

export const StarsButtons = (props) => {
  let size = 25;
  let ratingArray = [];

  const handleStarClick = (e, value) => {
    let target = e.target;
    console.log('target: ', target.size)
    props.setRating(value)
  }

  for(let i = 0; i < 5; ++i){
    ratingArray.push({index: i, rating: props.rating});
  }
  // console.log('ratingArray ', ratingArray)
  let rating = ratingArray.map((r)=> 
    <div key={r.index} style={{padding: '3px 3px', cursor: 'pointer'}} onClick={(e)=>{handleStarClick(e, r.index + 1)}}>
      <StarColored key={r.index} size={size} fillstar={props.rating > r.index ? 'yes' : 'no'} />
    </div>
  );
  return(
    <div style={{
      display: 'flex'
      // border: '2px solid red'
    }}>
      {rating}
    </div>
  )
}