import React, { Component, useState, useEffect, Fragment, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import { usePageTransitions } from '../../../../vinciToolbox';
import { BackIconOrange, NavBackIcon } from '../../../svg/svg_icons';
import { CCButton } from '../Plan'
import { NavButton } from '../../../components'; 
import { Modal } from '../../AssessPages/Modal';
import ReactRTE from './ReactRTE';
// import { Editor } from "@tinymce/tinymce-react";

import firebase from 'firebase';
import '../../../../firebase-init';
import '../../Phase.css';
// import '../Resources.css';


export const ChallengingAssignmentEdit=(props)=> {
  let pt = usePageTransitions('/ca_edit', 3);

  let transition = useSpring({
    transform: `translateX(${pt.animate ? 0 : props.screenSize.width}px)`,
    backgroundColor: '#e6e6e6', 
    position: 'absolute', top: 0, left: 0, 
    width: props.wrapper.width, 
    height: props.screenSize.height,
    backgroundColor: '#e6e6e6',
    overflowX: 'hidden',
    overflowY: 'auto',
    display: pt.render ? 'block' : 'none'
  });

  let [title, setTitle] = useState('');
  let [summary, setSummary] = useState('');
  let [description, setDescription] = useState('');
  let [showModal, setShowModal] = useState(false);
  const editorRef = useRef(null);
  
  let db = firebase.firestore();
  useEffect(()=>{
    let docRef = db.collection(`campbell_compass`).doc(`${firebase.auth().currentUser.email}_challenging_assigment`);
    docRef.get().then((doc) => {
      // console.log('Inside load challenging assignment', doc.data());
      let data = doc.data().challengingAssignment;
      if (doc && doc.exists) {
        setTitle(data.title);
        setSummary(data.summary);
        setDescription(data.description);
      }else{ // doc doesn't exist so create it.
        docRef.set({ 
          challengingAssignment: {
            title: title,
            summary: summary,
            description: description
          }
        },{ merge: true })
        .then(()=>{ 
          console.log('SUCCESS: Doc updated');
        }).catch((error)=> {
          console.log('Something went wrong with the document creation: ', error);
        });
      }
    }).catch(function(error) {
      console.log("Error getting document:", error);
    });
  },[pt.render]) 

  const handleSave = () => {
    // console.log('description :', description)
    let docRef = db.collection(`campbell_compass`).doc(`${firebase.auth().currentUser.email}_challenging_assigment`);
    // setDescription(editorRef.current.getContent());
    docRef.set({ 
      challengingAssignment: {
        title: title,
        summary: summary,
        description: description,  // editorRef.current.getContent(),
        type: 'challenging_assignment'
      }
    },{ merge: true })
    .then(()=>{
      console.log('SUCCESS: Doc updated');
    }).catch((error)=> {
      console.log('Something went wrong with the document creation: ', error);
    });
  }

  const handleNoSave = () => {
    setTitle('');
    setSummary('');
    setDescription('');
    setShowModal(false);  
    window.history.go(-1);
  }

  return(
    <Fragment>
    {pt.render ?
      <div style={{backgroundColor: '#cccccc'}}>
        <animated.div  className="Phases-sidein-page" style={transition}>
          <div className="Phases-subpage-header">
            <div className="Phase-subpage-header-icon" onClick={()=>{ setShowModal(true) } }>
              <NavButton variant="secondary" name="back" size={50} >
                <NavBackIcon height="50" name="challengingAssignments" />
              </NavButton> 
            </div>
            <div className="Phase-subpage-header-text" style={{margin: '0 auto'}}>Edit Content</div>
          </div>
          <div className="Assess-description-block" 
            style={{padding: 0, minHeight: 100, fontSize: '100%', width: '100%', backgroundColor: 'transparent',
          }}>
            <div style={{margin: 0, width: '100%'}}>
              <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
                <div style={{ background: 'transparent',
                  border: '2px solid #c6c6c6'
                }}>
                  <TitleEditor title={title} setTitle={setTitle} wrapper={props.wrapper}/>
                  <SummaryEditor summary={summary} setSummary={setSummary} wrapper={props.wrapper}/>
                  <ReactRTE description={description} setDescription={setDescription}/>
                </div>
              </div>
            </div>
          </div>
        </animated.div>
      </div>
      : ''
      }
      <Modal show={showModal}>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: props.wrapper.width - 0}}>
        <div style={{width: '100%'}}>
          <div style={{color: '#333', fontSize: '110%', textAlign: 'center', marginLeft: 10}}>
            Save changes before leaving?
          </div>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <div style={{display: 'flex', justifyContent: 'center', 
              marginTop: 30,
              width: '100%',
              cursor: 'pointer',
              fontSize: '120%',
              textAlign: 'center',
              color: '#777'
            }} onClick={()=> { handleNoSave() }}>
              Discard
            </div>
            <div style={{display: 'flex', justifyContent: 'center', 
                marginTop: 30,
                width: '100%',
                cursor: 'pointer',
                fontSize: '120%',
                textAlign: 'center',
                color: '#F5B01B'
              }} 
              onClick={()=>{ handleSave(); setShowModal(false); window.history.go(-1) } }
            >
              Save
            </div>
          </div>
        </div>
      </div>
      </Modal>
    </Fragment>
  )
}


const TitleEditor = (props) => {

  const handleChange = (e) => {
    props.setTitle(e.target.value)
  }

  const titleStyle = {
    width: props.wrapper.width,
    fontSize: '145%',
    fontWeight: 500,
    margin: '20px 0 0 0',
    padding: '3px 20px',
    boxSizing: 'border-box',
    border: '1px dashed rgba(0,0,0,0.4)',
    background: '#ffffff'
  }
  return(
    <div >
      <input type="text" value={props.title} placeholder="Title..." style={titleStyle} onChange={(e)=>handleChange(e)} />
    </div>
  )
}


const SummaryEditor = (props) => {
  const handleChange = (e) => {
    props.setSummary(e.target.value)
  }


  const summaryStyle = {
    width: props.wrapper.width,
    fontSize: '105%',
    fontWeight: 500,
    margin: '20px 0 15px 0',
    padding: '3px 20px',
    boxSizing: 'border-box',
    border: '1px dashed rgba(0,0,0,0.4)',
    background: '#ffffff'
  }
  return(
    <div >
      <textarea value={props.summary} placeholder="Summary..." style={summaryStyle} onChange={(e)=>handleChange(e)}  />
    </div>
  )
}


