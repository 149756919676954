import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import { usePageTransitions, usePathSegments, goHome } from '../../../vinciToolbox';
import { CustomCheck, ChallengingAssignmentIcon, LiveTrainingIcon } from '../../svg/svg_icons';
import getTimestamp from './getTimestamp'
import firebase from 'firebase';

let db = firebase.firestore();

const LiveLearning = (props) => {

  const getEnrolledStatus = (attendees) => {
    let displayName = firebase.auth().currentUser.displayName;
    if(attendees && attendees.indexOf( displayName ) !== -1 ){
      return true
    }else{
      return false
    }
  }

  let [enrolledCourses, setEnrolledCourses] = useState([]);
  
  useEffect(()=>{
    let db = firebase.firestore();
    let docRef = db.collection(`campbell_compass`).doc(`admin_tools`);
    docRef.get()
    .then(function(doc) {
      let courseData = doc.data().liveLearning;
      let enrolledCoursesArray = [];
      for(let i = 0; i < courseData.length; ++i){
        let enrolled = getEnrolledStatus(courseData[i].attendees );
        if(enrolled){
          enrolledCoursesArray.push({id: i, course: courseData[i]});
        }
      }
      console.log('enrolledCoursesArray=', enrolledCoursesArray)
      // setEnrolledCourses(enrolledCoursesArray);
    }).catch(function(error) {
      console.log("Error getting document:", error);
    });
  },[])

  // console.log('props.pt.render = ', props.pt.render)
  // if(enrolledCourses.length){
  //   console.log('enrolledCourses: ', enrolledCourses )
  // }


  var unsubscribe = null;
  const getRealtimeUpdates = () =>{
    unsubscribe = db.collection(`campbell_compass`).doc(`admin_tools`)
        .onSnapshot((doc)=>{
      if(doc && doc.exists) {
        let courseData = doc.data().liveLearning;
        let enrolledCoursesArray = [];
        for(let i = 0; i < courseData.length; ++i){
          let enrolled = getEnrolledStatus(courseData[i].attendees );
          if(enrolled){
            enrolledCoursesArray.push({id: i, course: courseData[i]});
          }
        }
        setEnrolledCourses(enrolledCoursesArray);
      }else{
        console.log('There was an onSnapshot() error')
      }
    })
  }
  useEffect(()=>{
    getRealtimeUpdates(); 
  },[1])
  if(!props.pt.render, props){
    if(unsubscribe){
      unsubscribe();
    }
  }

  const handleContinue = (index, data) => {
    console.log('inside handleContinue - index = ', index)
    // This logs the item identified by Index to the Competion Log 
    // Get indexed record from the Saved Resources doc
    let indexedRecord = {};
    let docRef = db.doc(`campbell_compass/admin_tools`);
    docRef.get().then((doc) => {
      if(doc && doc.exists){
        // console.log('doc.data()= ', doc.data())
        let liveLearning = doc.data().liveLearning;
        indexedRecord = liveLearning[index];
        
        docRef = db.doc(`campbell_compass/${firebase.auth().currentUser.email}_completed`); 
        let logArray = [];
        docRef.get()
        .then((doc) => {
          if(doc && doc.exists){ // DOCUMENT EXISTS, LOG THE INDEXED ITEM
            let completed = doc.data().completed;
            logArray = completed;
            // console.log('completed = ', completed)
            // Add timestamp element to logArray object.
            indexedRecord['timestamp'] = getTimestamp();
            indexedRecord['type'] = 'course'
            logArray.push(indexedRecord)
            console.log('logArray = ', logArray);
            docRef = db.doc(`campbell_compass/${firebase.auth().currentUser.email}_completed`);
            docRef.set({
              completed: logArray
            }, { merge: true })
            .then(()=>{
              console.log('SUCCESS - item added to completed log');
            }).catch((error)=> {
              console.log('you screwed up!: ', error);
            })

          }else{ // DOCUMENT DOESN'T EXIST, SO CREATE IT AND LOG THE INDEXED ITEM
            docRef = db.doc(`campbell_compass/${firebase.auth().currentUser.email}_completed`);
            docRef.set({
              completed: [indexedRecord] // Initialize the log
            })
            .then(() => {
              console.log('SUCCESS - Completed log created');
    
            }).catch((error) => {
              console.log('ERROR in set() operation', error)
            });
          }
        })

        // UNENROLL CURRENT USER
        docRef = db.doc(`campbell_compass/admin_tools`); 
        docRef.get()
        .then((doc) => {
          let liveLearning = doc.data().liveLearning;    // [index].attendees;
          console.log('liveLearning[index].attendees- before = ', liveLearning[index].attendees );

          let userName = firebase.auth().currentUser.displayName;
          const ind = liveLearning[index].attendees.indexOf(userName);
          if (ind > -1) {
            liveLearning[index].attendees.splice(ind, 1);
          }
          console.log('liveLearning[index].attendees- after = ', liveLearning[index].attendees );
          
          
          docRef = db.doc(`campbell_compass/admin_tools`); 
          docRef.set({
            liveLearning: liveLearning
          }, { merge: true })
          .then(() => {
            console.log('SUCCESS - user is unenrolled')
          })
          // Change docRef back to _completed so admin data is not written to Completed Log 
          docRef = db.doc(`campbell_compass/${firebase.auth().currentUser.email}_completed`);

        })
        .catch(() => {
          console.log('Couldn\'t retrieve document')
        })
        props.setShowModal(false)
      }
    }).catch((error) => {
      console.log('ERROR - could not retrieve the admin_tools document. ', error)
    });
  }

  const handleChecked = (id, course) => {
    // console.log('inside handleChecked - data = ', data)
    props.setModalContent(
      <div>
        <div>
          <div style={{fontSize: '105%', fontWeight: 600, color: '#000',  textAlign: 'center', margin: '10px 0 15px'}}>
            This action will move the course to Completed 
            and will unenroll you from the course.
          </div>
          {/*}
          <div style={{fontSize: '1--%', fontWeight: 600, color: 'tomato',  textAlign: 'center', margin: '0 0 20px 0'}}>
            This action is non-reversable
          </div>
          */}
        </div>
        <div style={{display: 'flex', justifyContent: 'space-around', marginTop: 15, fontSize: '120%', width: '100%'}}>
          <div style={{ color: '#808080', cursor: 'pointer'}} onClick={()=> props.setShowModal(false) }>
            Cancel
          </div>
          <div style={{
            color: '#F5B01B',
            cursor: 'pointer',
          }} 
            onClick={() => handleContinue(id)}>
            Continue  
          </div>
        </div>
      </div>
    )
    props.setShowModal(true);
  }

  let listOfEnrolledCourses = null;
  if(enrolledCourses){
    listOfEnrolledCourses = enrolledCourses.map((crs) => 
      <div key={crs.id} style={{margin: '0 -25px 0 -25px', padding: '10px 25px 15px 25px', // border: '1px solid magenta',
        display: 'flex', position: 'relative'
      }}>
        <div style={{position: 'absolute', width: '100%'
          // border: '1px solid red'
        }}>
          <div style={{position: 'absolute', left:7}}>
            <LiveTrainingIcon size={55} color="#F5B01B" />
          </div>
        </div>

        <div style={{width: '100%'}}>
          <div style={{marginLeft: 85, marginBottom: 15}}>
          <div style={{display: 'flex', justifyContent: 'space-between', fontWeight: 600, fontSize: '105%', color: '#333',}}>
            <div style={{marginBottom: 5}}>{crs.course.title}</div>
            <div style={{transform: 'scale(1.1)', cursor: 'pointer'}} onClick={(e) => handleChecked(crs.id, crs.course)}>
              <CustomCheck size={25} backgroundColor={false ? "#F9C908" : "#cccccc"} color="#555" borderColor={false ? "transparent" : "#c6c6c6"} />
            </div>
          </div>
            <div style={{fontSize: '90%', fontWeight: 500, color: '#000'}} >
              {crs.course.description}
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', margin: '7px 0 -15px 0', fontSize: '100%', color: '#000', fontWeight: 500,
              // border: '1px solid blue'
            }}>
              <div>
                Date: {crs.course.date}
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
  return(
    <div>
      {listOfEnrolledCourses.length ?
        <div style={{marginBottom: -20}}>
          <div style={{fontSize: '125%', fontWeight: 800, color: '#444', margin: '35px 25px 0px 25px', paddingBottom: 10, borderBottom: '1px solid #333' }}>
            Live Leadership Training
          </div>
          <div style={{padding: '10px 25px 5px 25px', minHeight: 50}}>
            {listOfEnrolledCourses}
          </div>
        </div>
      : ''
      }
    </div>
  )
}

export default LiveLearning;