import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import { usePageTransitions, usePathSegments } from '../../../vinciToolbox';
import { NavBackIcon, BigPlus, GoldBall, CustomEx } from '../../svg/svg_icons';
// import { CCButton } from '../Plan'
import { NavButton } from '../../components'; 
import { AutocompleteUsers } from '../../components';
import { Modal } from './Modal';

import firebase from 'firebase';
import '../../../firebase-init';
import '../Phase.css';
// import '../Resources.css';

let db = firebase.firestore(); // Creates database reference

export const Authorizations=(props)=> {
  let pt = usePageTransitions('/authorizations', 2);

  let segment = usePathSegments(null);


  let transition = useSpring({
    transform: `translateX(${pt.animate ? 0 : props.screenSize.width}px)`,
    position: 'absolute', top: 0, left: 0, 
    width: props.wrapper.width, 
    height: props.screenSize.height,
    overflowX: 'hidden', overflowY: 'auto',
    backgroundColor: '#e6e6e6',
    display: pt.render ? 'block' : 'none'
  });

  // let refSubpageHeader = useRef(null);
  // let contentWrapperHeight = refSubpageHeader.current ? refSubpageHeader.current.offsetHeight : 0;

  let [value, setValue] = useState('');
  let [clear, setClear] = useState('');

  let [showModal, setShowModal] = useState(false);
  let [showDeleteModal, setShowDeleteModal] = useState(false);
  let [thisPage, setThisPage] = useState('');
  let [managerName, setManagerName] = useState('');
  let [authorized, setAuthorized] = useState([])

  useEffect(()=>{
    setThisPage(segment[2] && segment[2].split(':')[0])
  },[segment[2]]);

  useEffect(()=>{
    setManagerName( props.user && props.user.managerName );
  },[props.user])

  // Initialize authorized list
  useEffect(()=>{
    let docRef = db.collection(`campbell_compass`).doc(`${props.user && props.user.email}_my_authorizations`);
    let tempList = [];
    docRef.get()
    .then((doc)=>{
      if(doc && doc.exists){
        tempList = doc.data().authorized;
        setAuthorized(tempList)
      }
    }).catch((error)=> {
      console.log('The list is empty: ', error);
    })
  },[props.user])

  let [itemToDelete, setitemToDelete ] = useState('');
  const handleDelete = (lineItem) => {
    console.log('k: ', lineItem.name);
    setitemToDelete(lineItem);
    setShowDeleteModal(true);
  }

  const handleDeleteFromDB = () => {
    console.log('itemToDelete: ', itemToDelete);

    let docRef = db.collection(`campbell_compass`).doc(`${props.user && props.user.email}_my_authorizations`);
    let tempList = [];
    let editedList = [];
    docRef.get()
    .then((doc)=>{
      if(doc && doc.exists){
        tempList = doc.data().authorized;
        for(let i = 0; i < tempList.length; ++i){
          if(i != itemToDelete.index ){
            editedList.push(tempList[i])
          }
        }
        console.log('editedList: ', editedList);
        docRef.set({
          authorized: editedList
        }, { merge: true })
        .then(()=>{
          console.log('authorized list updated successfully.');
          setAuthorized(editedList)
        }).catch((error)=> {
          console.log('Error in update assessment authorizations: ', error);
        })

      }
    }).catch((error)=> {
      console.log('The list is empty: ', error);
    })
    
    setShowDeleteModal(false);
  }

  let tempArray = []
  for(let i = 0; i < authorized.length; ++i){
    tempArray.push( {index: i, name: authorized[i]} );
  }
  let theList = tempArray.map((lineItem)=> 
    //console.log('lineItem: ', lineItem)
      <div style={{marginTop: 10, marginRight: -35, display: 'flex'}}
        key={lineItem.index} 
      >
        {lineItem.name}
        <div style={{marginTop: -12, padding: 10, cursor: 'pointer'}}
          onClick={()=>handleDelete(lineItem)}
        >
        {/*<DeleteIcon size={20} />*/}
          <CustomEx size={20} backgroundColor="#e6e6e6" color="#666" borderColor="#999" />
        </div>
      </div>
  )

  const updateList = () => {
    // console.log('add name to the list: ', value)
    let docRef = db.collection(`campbell_compass`).doc(`${props.user.email}_my_authorizations`);
    let tempList = [];
    docRef.get()
    .then((doc)=>{
      if(doc && doc.exists){
        tempList = doc.data().authorized;
        setAuthorized(tempList)
        // test if value already exists
        let temp = []
        for(let i = 0; i < tempList.length; ++i){
          if(value != tempList[i]){
            temp.push(tempList[i])
          }
        }
        console.log('temp: ', temp)
        temp.push(value);
        // console.log('tempList: ', temp)
        tempList = temp;
        docRef.set({
          authorized: tempList
        }, { merge: true })
        .then(()=>{
          console.log('authorized list updated successfully.');
          setAuthorized(tempList)
        }).catch((error)=> {
          console.log('Error in update assessment authorizations: ', error);
        })
      }else{
        docRef.set({
          authorized: [value]
        }).then(()=>{
          setAuthorized([value])
          console.log('The document was created')
        }).catch((error)=> {
          console.log('Error in update assessment authorizations: ', error);
        })
      }

    }).catch((error)=> {
      console.log('Something screwed up: ', error);
    });

    setClear(true);
    setShowModal(false);
  }

  let [nameFound, setNameFound] = useState(false);
  
  useEffect(()=>{
    if(!value){
      // console.log('THE FIELD IS EMPTY')
      setNameFound(false)
    }else{
      // console.log('THE FIELD HAS STUFF IN IT')
    }
  })

  const handleContinue = () => {
    updateList(value, true); // Enable the blue Continue link
  }

  return(
    <Fragment>
      <div style={{backgroundColor: '#cccccc'}}>
        <animated.div  className="Phases-sidein-page" style={transition}>
          <div className="Phases-subpage-header">
            <div className="Phase-subpage-header-icon" onClick={()=>{ window.history.go(-1)} }>
              <NavButton variant="secondary"  size={50} >
                <NavBackIcon height="50" name="authorize-1" />
              </NavButton> 
            </div>
            <div className="Phase-subpage-header-text" style={{margin: '0 auto'}}>Authorizations</div>
          </div>

          <div className="Assess-description-block" 
            style={{minHeight: 75, fontSize: '100%', lineHeight: '1.4em', padding: '20px 25px 5px 25px',width: '100%', backgroundColor: '#f4f4f4', 
          }}>
            <div>
              The list below defines who is currently authorized to assess you. 
              When you receive each assessment, remove them from the list.
            </div>
            <br />
          </div>
            <div style={{display: 'flex', flexDirection: 'column', alignItems : 'center', paddingTop: '5%', 
            fontWeight: 600, color: '#5f5f5f', height: 'auto', width: props.wrapper.width }}>
            <div  style={{textAlign: 'center', width: '100%', fontSize: '90%'}}>
              <i>Note: You and your manager are always authorized</i>
            </div>
            <br/>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              {theList}
            </div>
            <div style={{margin: '30px 0 20px 0'}} onClick={()=> setShowModal(true)}>

            {/*<NavButton size={60} >
                <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', 
                }}>
                  <div style={{margin: '7px 0 0 8px'}}><BigPlus size={40} color='#808080' /></div>
                </div>
              </NavButton>*/}

              <NavButton size={60} >
              <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', 
              }}>
                <div style={{position: 'relative', top: -25, left: -25}}>
                  <div style={{position: 'absolute', top: 0, right: 0, bottom: 0, left: 0}}>
                    <GoldBall size={50} name="authorize1" />
                  </div>
                  <div style={{position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, margin: '7px 0 0 8px'}}>

                      <BigPlus size={40} color='rgba(0,0,0,0.3)' /> 

                  </div>
                </div>
              </div>
            </NavButton> 



              <div style={{fontSize: '90%', color: '#a0a0a0', marginTop: 12}}>Add name</div>
            </div>
          </div>
        </animated.div>
      </div>

      <Modal show={showModal ? true : false} width={'90%' } >
        <div style={{display: 'flex', flexDirection: 'column', width: props.wrapper.width - 0, marginTop: 10}}>
          <div >
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <div className="OtherAssessment" 
                style={{display: 'flex', justifyContent: 'center', 
                position: 'relative', margin: '0px 0 0px 0', width: '100%'}}
              >
                <div style={{position: 'static', width: '100%', maxWidth: 500, display: 'flex', justifyContent: 'center'}}>
                  <AutocompleteUsers value={value} setValue={setValue} 
                    clear={clear} setClear={setClear} nameFound={nameFound} setNameFound={setNameFound} 
                    setShowModal={setShowModal} handleContinue={handleContinue}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal show={thisPage==='/authorizations' && showDeleteModal ? true : false} width={'80%'}>
        <div style={{display: 'flex', flexDirection: 'column', width: props.wrapper.width - 0}}>
            <div style={{display: 'flex', justifyContent: 'center', fontSize: '130%', color: '#333333'}}>
              Remove {itemToDelete.name} ?
            </div>
          <div style={{display: 'flex', justifyContent: 'space-around', marginTop: 25, fontSize: '130%', width: '100%'}}>
            <div style={{ color: '#999999'}} onClick={()=> { setClear(true); setShowDeleteModal(false) } }>
              Cancel
            </div>
            <div style={{
              color: `#ff0000`,
              cursor: 'pointer',
            }} 
              onClick={()=> handleDeleteFromDB()  }>
              Remove  
            </div>
          </div>
        </div>
      </Modal>  

    </Fragment>
  )
}

